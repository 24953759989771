import React, { useState } from "react";
import axios from "axios";
import { Link } from 'react-router-dom'
import "./style.css";
import "./login.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default function Tour() {
  const secondstyle = {
    color : "white",
    textAlign : "center",
    padding : "20px 0px",
    background : "#F40033"
    
}
    const firststyle = {
        textAlign : "center",
        margin : "20px auto"
    }
    const url = "http://192.168.1.67:8083/api/tour/save-or-update";
  const [data, setData] = useState({
    name: "",
    mobileNumber: "",
    personNumber: "",
    email: "",
    country: "",
    address: ""
  });

  function submit(e) {
    const params = {
      name: data.name,
      mobileNumber: data.number,
      address: data.address,
      personNumber: data.person,
      country: data.country,
      email: data.email
    };
    console.log(params)
    e.preventDefault();
    axios.post(url, params).then((response) => {
      const data = response.data;
      console.log(response);
      if (data.success) {
        toast.success(data.message);
      } else {
        toast.error(data.message);
      }
    });
    
  }
       function handle(e){
           const newData = {...data}
           newData[e.target.id] = e.target.value
           setData(newData)}
  return (
    <div>
            <div className="slider">
      <div
        id="carouselExampleIndicators"
        className="carousel slide"
        data-bs-ride="carousel"
      >
        <div className="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="0"
            className="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="3"
            aria-label="Slide 4"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="4"
            aria-label="Slide 5"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="5"
            aria-label="Slide 6"
          ></button>
        </div>
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img src="tour/1.jpg" className="d-block w-100" alt="..." />
          </div>
          <div className="carousel-item">
            <img src="tour/2.jpg" className="d-block w-100" alt="..." />
          </div>
          <div className="carousel-item">
            <img src="tour/3.jpg" className="d-block w-100" alt="..." />
          </div>
          <div className="carousel-item">
            <img src="tour/4.jpg" className="d-block w-100" alt="..." />
          </div>
          <div className="carousel-item">
            <img src="tour/5.jpg" className="d-block w-100" alt="..." />
          </div>
          <div className="carousel-item">
            <img src="tour/6.jpg" className="d-block w-100" alt="..." />
          </div>
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="prev"
        >
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="next"
        >
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
    
    <section className="total_guru">
      <h1 style={firststyle}>Why Pilgraimage tour with Us?</h1>
    <section className="total_tour wrapper">
      
      <div className="place_one">
        <img src="img/guru_1.png" alt=""/>
      </div>
      <div className="guru_description">
        <h4>Buddhist Teaching & Meditation Center  offers well managed services including luxury bus or flight ticket services, well guidance, pre-planned food, beverage, first add medical services, related teachings, meditation course, organize puja for various propose, offer video documentary of participants of visiting sites. 
          </h4>
      </div>
      <div className="form_input">
      <form onSubmit={(e) => submit(e)}>
        <div >
          <h3>Tour Form</h3>
          <div className="inputs">
            <p>Name </p>
            <input type="text" value={data.name} id="name"
                onChange={(e) => handle(e)} placeholder="Enter your name"/>
          </div>
          <div className="inputs">
            <p>Email </p>
            <input value={data.email}  id="email"
                onChange={(e) => handle(e)} type="text" placeholder="Enter your email"/>
          </div>
          <div className="inputs">
            <p>Contact Number </p>
            <input value={data.number} id="number"
                onChange={(e) => handle(e)} type="text" placeholder="Enter contact number"/>
          </div>
          <div className="inputs">
            <p>Number of person</p>
            <input value={data.person} id="person"
                onChange={(e) => handle(e)} type="number" placeholder="number of person"/>
          </div>
          <div className="inputs">
            <p>Country </p>
            <input value={data.country} id="country"
                onChange={(e) => handle(e)} type="text" placeholder="Enter country you want to visit"/>
          </div>
          <div className="inputs">
            <p>Address </p>
            <input value={data.address} id="address"
                onChange={(e) => handle(e)} type="text" placeholder="Enter your address"/>
          </div>
          <button>Submit</button>
          <ToastContainer />
        </div>
        </form>
      </div>
    </section>
  </section>
   
    <h1 style={firststyle}>Pilgraimage tour <br/> in different Countries</h1>
    <section className="wrapper tour_photos">
      <div className="tour_photo_one">
        <Link preventScrollReset={true}to="/nepaltour">
        <h3>6D/5N</h3>
        <img src="img/baudha.jpg" alt=""/>
        <h2>Nepal</h2>
      </Link>
      </div>
      <div className="tour_photo_one">
        <Link preventScrollReset={true}to="/indiatour">
          <h3>15D/14N</h3>
        <img src="img/india.jpg" alt=""/>
        <h2>India</h2>
      </Link>
      </div>
      <div className="tour_photo_one">
        <a >
        <h3>13D/12N</h3>
        <img src="img/china.jpg" alt=""/>
        <h2>China</h2>
      </a>
      </div>
      
      
      <div className="tour_photo_one">
        <a ><h3>10D/9N</h3>
        <img src="img/thai.webp" alt=""/>
        <h2>Thailand</h2>
      </a>
      </div>
      <div className="tour_photo_one">
        <a >
        <h3>9D/8N</h3>
        <img src="img/bhutan.jpg" alt=""/>
        <h2>Bhutan</h2>
      </a>
      </div>
      <div className="tour_photo_one">
        <a >
        <h3>10D/9N</h3>
        <img src="img/sirlanka.jpg" alt=""/>
        <h2>Sirlanka</h2>
      </a>
      </div>
    </section>
    <section className="mission_vision">
      <div className="wrapper misson_IT">
        <div className="why_IT">
          <a 
            ><img src="tour/yolmo group/YOLMOPA SELECTED/Amaravathi Buddha Stupa.jpg"  alt="..." />
            <div className="description">
              <h3>Our Services</h3>
              <h5>
              Buddhist Teaching and Meditation Center offers various services with long experiences.
With the objectives of enlightening devotees Ven. Khen Rinpochela taking groups of devotees to different pilgrimage tour and offers teachings and train mind with various related meditation and practices.
 {/* Khen Rinpochela also offers to practitioners various transmission (Lung) and teachings (Thi) about important secret practices. Venerable with more than two decades' experience of leading devotees with profound teaching Ven. Khen Rinpoche focuses more to lead devotees to pilgrimage tour since devotees remain free from all worldly responsibility and have a good chance to learn and practice dharma   direct from Venerable Khen Rinpochela.
Our services includes flight ticketing, arranging food, hotels, puja, meditation, medical first add services, communication facilities, teaching and medication course, documentary movies, nad live broadcasting  etc. */}
              </h5>
            </div>
          </a>
        </div>
        <div className="why_IT">
          <a 
            ><img src="tour/flight.jpg" alt="" />
            <div className="description">
              <h3>Supporting Partners</h3>
              <h5>
              BTMC  jointly work with various tours and travel company in Nepal and India. We are  working with those companies for air ticketing , train ticketing , vehicle hiring, hotel booking etc. for the last around two decades.  Our devotees are always confident with us while traveling even though in metro municipality city or any corner of the countries. 
              </h5>
            </div>
          </a>
        </div>
      </div>
    </section>
    <h1 style={secondstyle}>Previous Tour Groups</h1>
    <section className="tour_images images_tour_previous">
    
    <div className="temporary_img wrapper">
              
              <img src="tour/BOUDHA GROUP/Boudha selected/Ajanta Cave, Maharastra.jpg" alt=""/>
              <img src="tour/BOUDHA GROUP/Boudha selected/Anupu University, South India.jpg" alt=""/>
              <img src="tour/BOUDHA GROUP/Boudha selected/Ladakh.jpg" alt=""/>
              <img src="tour/BOUDHA GROUP/Boudha selected/Shree Parwat, South India.jpg" alt=""/>
              <img src="tour/BOUDHA GROUP/Boudha selected/Srawasti, UP.jpg" alt=""/>
              <img src="tour/BOUDHA GROUP/Boudha selected/Ajanta Cave, Maharastra.jpg" alt=""/>
              <img src="tour/PLILGRIMAGE/DSC_8324.jpg" alt=""/>
              <img src="tour/PLILGRIMAGE/DSC_9176.jpg" alt=""/>
              <img src="tour/yolmo group/YOLMOPA SELECTED/Aelora Cave (4).jpg" alt=""/>
              <img src="tour/yolmo group/YOLMOPA SELECTED/Amaravathi Buddha Stupa.jpg" alt=""/>
              <img src="tour/yolmo group/YOLMOPA SELECTED/Aurangabad (2).jpg" alt=""/>
              <img src="tour/yolmo group/YOLMOPA SELECTED/Mumbai India Gate.jpg" alt=""/>
          </div>  
    </section>
     
    </div>
  )
}
