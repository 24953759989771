import React from 'react'

export default function Temporary() {
    const style = {
        textAlign : "center",
        margin : "50px auto"
    }
    const secondstyle = {
        textAlign : "center",
        margin : "30px auto"
    }
  return (
    <div>
         <h1 style={style}>Temporary Monastic college <br/> construction at Jorpati,
      Kathmandu,Nepal</h1>
    <section className="full_monastic wrapper">
      <div className="full_monastic_img_desc">
        <h4>
          Recently around 30 monks and teaching staffs live here at shelter.
          We have only one temporary shelter where we organize puja, meetings,
          gatherings, teaching classes and even we use it aews bed room.
        </h4>
      </div>
      <div className="full_monastic_img">
        <img src="img/group.png" alt="" />
      </div>
    </section>
    <section className="full_monastic wrapper">
      <div className="full_monastic_img">
        <img src="temporary monastic college/IMG_20200928_152136.jpg" alt="" />
      </div>
      <div className="full_monastic_img_desc">
        <h4>
          Monastic college needs 3 class rooms, 1 digital library, 3 dormitory with the
capacity of 150 students, 1 office room, 1 kitchen, 1 large pray hall for meditation
practice, group teachings and group meetings.
        </h4>
      </div>
    </section>
    <section className="wrapper temporary">
      <h3 style={secondstyle}>Monks and Teaching Staffs </h3>
      <div className="temporary_img">
          <img src="img/okey (1).png" alt=""/>
          <img src="img/okey (2).png" alt=""/>
          <img src="img/okey (3).png" alt=""/>
          <img src="img/okey (4).png" alt=""/>
      </div>    
  </section>
    <section className="wrapper temporary">
      <h3 style={secondstyle}>Project update</h3>
        <div className="temporary_img">
            <img src="temporary monastic college/IMG_20200928_152120.jpg" alt=""/>
            <img src="temporary monastic college/IMG_20200928_152136.jpg" alt=""/>
            <img src="temporary monastic college/IMG_20200928_152149.jpg" alt=""/>
            <img src="temporary monastic college/IMG_20200928_152715.jpg" alt=""/>
            <img src="temporary monastic college/IMG_20210619_164551.jpg" alt=""/>
            <img src="temporary monastic college/IMG_20200928_160920.jpg" alt=""/>
            <img src="temporary monastic college/IMG_20210615_122151.jpg" alt=""/>
            <img src="temporary monastic college/IMG_20210615_122701.jpg" alt=""/>
            <img src="temporary monastic college/IMG_20210618_085706.jpg" alt=""/>
            <img src="temporary monastic college/IMG_20200928_152715.jpg" alt=""/>
        </div>    
    </section>
    </div>
  )
}
