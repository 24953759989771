import React, { useState } from "react";
import axios from "axios";
import { Link } from 'react-router-dom'
import "./style.css";
import "./login.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Login() {
  const url = "http://192.168.1.67:8083/api/auth/signin";
  const [data, setData] = useState({
    username: "",
    password: "",
  });

  function submit(e) {
    const params = {
      username: data.username,
      password: data.password,
    };
    e.preventDefault();
    axios.post(url, params).then((response) => {
      const data = response.data;
      console.log(response);
      if (data.success) {
        toast.success(data.message);
      } else {
        toast.error(data.message);
      }
    });
  }
       function handle(e){
           const newData = {...data}
           newData[e.target.id] = e.target.value
           setData(newData)}

  return (
    <>
      <p className="display"></p>
      <form onSubmit={(e) => submit(e)}>
        <section className="fullbox">
          <div className="login_box">
            <div className="logo_img_2">
              <a href="index.html">
                {" "}
                <img src="img/logo.png" alt="" />
              </a>
              <h2>RDCCK</h2>
            </div>
            
            <div className="inputs">
               <p>Username :</p>
              {" "}
              <input
                type="text"
                placeholder="Enter your username :"
                value={data.username}
                onChange={(e) => handle(e)}
                id="username"
              />
              {" "}
            </div>
            {" "}
            <div className="inputs">
               <p>Password :</p>
              {" "}
              <input
                type="password"
                placeholder="Enter your password :"
                value={data.password}
                onChange={(e) => handle(e)}
                id="password"
              />
              {" "}
            </div>
            {" "}
            <button className="signup login_button" type="submit">
               <p>Login</p>
              {" "}
            </button>
             <ToastContainer />
            {" "}
          </div>
          {" "}
        </section>
        {" "}
      </form>
      {" "}
    </>
  );
}
export default Login;

