import React from "react";
import "./style.css";

export default function Monastic() {
  const style = {
    textAlign: "center",
    margin: "30px auto",
  };
  return (
    <>
      <h1 style={style}>Stage of a monastic construction</h1>
      <section className="full_monastic wrapper">
        <div className="full_monastic_img_desc">
          <h4>
            Building of monastic school was started to construct from 2016 and
            now has been stopped from end of 2017 since the lack of financial
            managment. Land of monastic school is about 105 ropani and one
            monastic building was being constructed with the income of dharma
            activities like taking pilgrimage tour to India and Nepal but there
            were no progress in the construction in 2018 since our monastic
            school could not organize any pilgrimage tour program for the last
            one and half year.
          </h4>
        </div>
        <div className="full_monastic_img">
          <img src="img/project_1.jpg" alt="" />
        </div>
      </section>
      <section className="full_monastic wrapper">
        <div className="full_monastic_img">
          <img src="img/Screenshot 2022-10-02 102615.png" alt="" />
        </div>
        <div className="full_monastic_img_desc">
          <h4>
            Till now we are using these small temporary halls for residence,
            Assembly hall and class rooms. <br />
            There are two hall 20x40 fits in sized. <br />
            This is monastry and class room.
          </h4>
        </div>
      </section>
      <section className="obstacles wrapper">
        <h1>Obstacles arise in between us</h1>
        <h4>
          Major obstacle to build up the monastic school is financial problems
          since school is not able to collect donation from donors and not being
          able to organize pilgrimage tour as before. <br />
          Our school is being established and running according to plan and
          vision of Venerable Khen Rinpoche Sonam Gyurme Tamang. Ven. Khenpo
          Sonam Gyurme Tamang is the senior Khenpo in our Tamang community and
          very active and popular in entire Nepal. Ven Khenpo wants to preserve
          and spread Buddha dharma all over Nepal and world with traditional
          Buddhism education and practical concepts by establishing monastic
          school, Sedra and meditation center. <br />
          Especially Ven. Khenpo is one of the senior and active lecturer, and
          he is being well known in the field of academic and Buddhist media
          sectors across Nepal. He is giving lecture in two colleges affiliated
          to Lumbini University of Govt. of Nepal and involved in televisions.
          Ven. Khenpo has started online TV by his own efforts from 2009 and now
          being little more effective and serving society by broadcasting live
          religious program of renowned masters.
        </h4>
      </section>
      <section className="our_products">
        <h1>Pictures of monastic school</h1>
        <div className="wrapper products_img_details">
          <div className="product">
            <img src="photos/14.jpg" alt="" />
          </div>
          <div className="product">
            <img src="photos/17.jpg" alt="" />
          </div>
          <div className="product">
            <img src="photos/7.jpg" alt="" />
          </div>
          <div className="product">
            <img src="photos/9.jpg" alt="" />
          </div>
          <div className="product">
            <img src="photos/10.jpg" alt="" />
          </div>
          <div className="product">
            <img src="photos/11.jpg" alt="" />
          </div>
          <div className="product">
            <img src="photos/12.jpg" alt="" />
          </div>
          <div className="product">
            <img src="photos/1.jpg" alt="" />
          </div>
          <div className="product">
            <img src="photos/13.jpg" alt="" />
          </div>
          <div className="product">
            <img src="photos/16.jpg" alt="" />
          </div>
          <div className="product">
            <img src="photos/18.jpg" alt="" />
          </div>
          <div className="product">
            <img src="photos/3.jpg" alt="" />
          </div>
          <div className="product">
            <img src="photos/22.jpg" alt="" />
          </div>
          <div className="product">
            <img src="photos/14.jpg" alt="" />
          </div>
          <div className="product">
            <img src="photos/20.jpg" alt="" />
          </div>
          <div className="product">
            <img src="photos/15.jpg" alt="" />
          </div>
        </div>
      </section>
    </>
  );
}
