import React from 'react'
import "./style.css"
export default function Retreat() {
    const style = {
        textAlign : "center",
        margin : "50px auto"
    }
    const secondstyle = {
        textAlign : "center",
        margin : "20px auto"
    }
  return (
    <>
        <h1 style={style}>Retreat Centre at Kakani, <br/> Nuwakot,Nepal</h1>
      <section className="full_monastic wrapper">
        <div className="full_monastic_img_desc">
          <h4>
              We have specific one year retreat program for RDCCK students .
              Many dharma masters and practitiners have requested to organize retreat
              programs in Nepali languages. In Nepal there is no retreat centres where Nepali
              masters can receive entire retreat cources in Nepali so demand and requests are
              very high but due to lack of financial supporters we were unable to build this
              centre from 2016. In present, there is small retreat house which is uncompleted
              till now from 2019.
          </h4>
        </div>
        <div className="full_monastic_img">
          
          <img src="img/one (7).jpeg " alt=""/>
        </div>
      </section>
      <section className="full_monastic wrapper">
        <div className="full_monastic_img">
          <img src="img/trust.jpg" alt="" />
        </div>
        <div className="full_monastic_img_desc">
          <h4>
              Now, In first stage we are planing to build retreat centre with 24 rooms.
              Practitioners from any counties can participate here for retreat programs in
              future. We request generious donors to support retreat centre.
          </h4>
        </div>
      </section>
      <section className="wrapper temporary">
        <h1 style={secondstyle}>Project update</h1>
          <div className="temporary_img">
              
              <img src="selectied for meditation center/1.jpeg" alt=""/>
              <img src="selectied for meditation center/9.jpeg" alt=""/>
              <img src="selectied for meditation center/Aryawalokeshor.jpeg" alt=""/>
              <img src="selectied for meditation center/back.jpeg" alt=""/>
              <img src="selectied for meditation center/meditation place.jpeg" alt=""/>
              <img src="selectied for meditation center/1 .jpg" alt=""/>
          </div>    
      </section>
    </>
  )
}
