import React from 'react'
import "./style.css"
export default function Indiatour() {
    const style = {
        textAlign : "center",
        margin : "20px "
    }
  return (
    <>
        <h1 style={style}>Tour Packages</h1>
    <section className="pacakages wrapper">
      
      <div className="package">
  
       <h3>  15 Days Nepal and India Pilgrimage Tour and Practices 
        (Pilgrimage tour by bus) </h3>
        <h4>
          Day 1,  2 and 3   Lumbini, Kudan, Kapilvastu, Niglihawa, Gotihawa, Devdaha<br/>
          Day 4  Sravasti<br/>
          Day 5 Sankisha<br/>
          Day 6 and 7  travel to Sarnath and Sarnath sightseeing<br/>
          Day 8, 9 and 10  Bodhgaya, Rajgiri, Nalanda, Gaya, <br/>
          Day  11 and 12  Vaishali, Keshariya <br/>
          Day 13  Kushinagar<br/>
          Day 14 way to Kathmandu<br/>
          Day 15 Kathmadu  <br/>
           <br/>
</h4>
      </div>
      <div className="second_package">
      <h3>  
7 Days Mumbai and Ajanta Ellora Pilgrimage Tour
(Pilgrimage tour by flight)
 </h3>
        <h4>
        Day 1, 2,  3 and 4     Mumbai pilgrimage and sightseeing<br/>
Day 5 and 6      Ajanta and Ellora<br/>
Day 7 Mumbai to Kathmandu<br/>

           <br/>
</h4>
      </div>
    </section>
    <section className="pacakages wrapper">
      
      <div className="package">
      <h3>7 Days Tsopema, Dharamshala Pilgrimage Tour 
      (Pilgrimage tour by flight)</h3>   
<h4>

  Day 1     Delhi (Night travel to Tsopema)<br/>
  Day 2 and 3    Tsopema<br/>
  Day 4 and 5  Dharamshala (Night travel to Delhi)<br/>
  Day 6  Delhi sightseeing<br/>
  Day 7 Back to Kathmandu or other destination  <br/>
  
</h4>
    
      </div>
      <div className="second_package">
     <h3>15 Days South India Pilgrimage Tour and Practices 
(Pilgrimage tour by bus)
</h3>   
<h4>
Day 1 Kathmandu to Sravasti travel<br/>
Day 2    Sravasti pilgrimage sightseeing and practices<br/>
Day 3   Sravasti to Sanchi travel<br/>
Day 4  Sanchi stupa visit, preaching (Night travel Sanchi to Ajanta)<br/>
Day 5 and 6 Ajanta, Ellora sightseeing and teachings  (night travel to Mumbai)<br/>
Day 7, 8 and 9  Mumbai sightseeing, Kahaneri Cave, Thoema Nagmo Cave, Film ciry (night travel to Nagarjuna Sagar)<br/>
Day 10   Nagarjuana Sagar sightseeing<br/>  
Day 11  Travel to Amravati and sightseeing<br/>
Day 12 travel to Bhuwaneswar <br/>
Day 13    Sight seeing <br/>
Day 14 and 15   Bhuwaneswar-Birganj-Kathmandu<br/>

  
</h4>
      </div>
    </section>
    <section className="body_part wrapper">
        <h1>India Pilgraimage</h1>
        <h1>15 Days Nepal and India Pilgrimage Tour and Practices </h1>
        <h4>15 days Nepal and India pilgrimage tour and practices package includes travel, food, hotel stay, tickets, documentary etc.
            It covers Lumbini, Kudan, Kapilvastu,Niglihawa, Gotihawa,Devdaha, Sravasti,Sankisha, Sarnath, Bodhgaya, Gaya, Griddhakut Parwat, Rajgiri, Nalanda, Vaishali, Keshariya, Kushinagar.
            BTMC offers well managed services including luxury bus, well guidance, pre-planned food, beverage, medical first add services, teachings, meditation course, video documentary.
            
          </h4>
          <h4>1 <b>	Bodh Gaya:</b> <br/>
            Bodh Gaya is a sacred Buddhist site with Mahabodhi Temple in Gaya District of Bihar in India. This place is popular all around world as it is said that Gautama Buddha attained enlightenment here. It is related to the life of Gautama Buddha and is leading pilgrimage sites for all the devotees of Buddhism. As Siddhartha, he sacrificed his pleasure life in the Palace and moved out and did self-mortification for six years here at Bodhagaya and achieved enlightenment. Then he discovered Eight-fold path and four noble truths that changed the perspective of life of the whole world. <br/>
            The Buddhist disciples of Buddha started to visit the place during the full moon in the month of Vaisakh and gradually the place became Bodh Gaya, the day of enlightenment as Buddha Purnima and the tree as the Bodhi Tree. The history of Bodh Gaya is captured in many inscriptions and pilgrimage accounts. Among these accounts Chinese pilgrims Faxian from 5th century and Xuanzang in the 7th century are of great importance. It was the sacred place for Buddhist devotees until it was ruined by Turkic armies in the 13th century. It was not in use till 18th century.
            
            </h4>
            <div className="tour_images">
                <div className="tour_images_image">
            <img src="img/image17.png" alt=""/></div>
            <div className="tour_images_image"><img src="img/image18.png" alt=""/></div>
        
        </div>
        <h4>2 <b>Sarnath:</b>	<br/>
            It is the site of the first Sermon and where Lord Buddha taught the four noble truths about life and death. This site includes beautiful Dhamek Stupa and ancient Buddhist monastery.
            <br/>
            It is a place situated at the north-east of Varanasi near the convergence of the Ganges and Varuna rivers in Uttar Pradesh, India. There is a deer park in Sarnath where Gautama Buddha first taught the Dharma, and also the place where Buddhist Sangha came into lives by the Nirvana of Kondanna. This place is regarded as Isipatana and is suggested by Buddha as one of the four places of pilgrimage to visit for his followers. It was also the place of Buddha's Dhammacakkappayattana Sutta, which was his first teaching after obtaining enlightenment.
            
            </h4>
            <div className="tour_images">
                <div className="tour_images_image">
            <img src="img/image19.png" alt=""/></div>
            <div className="tour_images_image"><img src="img/image20.png" alt=""/></div>
        
        </div>
        <h4>3 <b>Kushinagar:</b>	<br/>
            This site is the place of Buddha's parinirvana and home for many meditation and prayers offering site in India. It has remains of ancient stupas and Parinirvana Stupa.
            <br/>
            Kushinagar is a sacred site in the Kushinagar district at Uttar Pradesh in India. It is believed that Gautama Buddha received Parinirvana after his death. This place is the heart of maximum Buddhist pilgrimage that wishes to visit this place at least for once in a life time. The recent Kushinagar is recognized with Kusavati and Kushinara. Once in the medieval period, Kushinagar was undertaken by Kultury Kings. Kushinara was lost into oblivion after 12th century and Padrauna was ruled by a Rajput adventurer, Madan Singh in early 15th century. However, modern Kushinagar came again into existence in the 19th century. </h4>
            <div className="tour_images">
                <div className="tour_images_image">
            <img src="img/image21.png" alt=""/></div>
            <div className="tour_images_image"><img src="img/image22.png" alt=""/></div>
        
        </div>
        <h4>4 <b>Shravasti:</b>	<br/>
            During the lifetime of Gautama Buddha, Shravasti was one of the six largest cities. The city was situated in the fertile Gangetic plains now at Uttar Pradesh, India. It is a closely related place with the life of Buddha who is believed to have spent 24 Chaturmases here. Old stupas, majestic viharas and other ancient temples can be found here that are associated with Buddha.
            <br/>
            This place is blessed because Gautama Buddha passed his greater part of his monastic life here. The important monasteries in Shravasti were Jetavana and the Pubbarama. It also maintained the monastery of Rajakarama which was built by Pasenadi, opposite Jetavana. It was the home for many monks and nuns. The main supporters of Buddha were Anathapindika, Visakha, Suppavasa and Pasenadi. It is said that Buddha spent twenty-five rainy seasons in Shravasti.
            
            </h4>
            <div className="tour_images">
                <div className="tour_images_image">
            <img src="img/image23.png" alt=""/></div>
            <div className="tour_images_image"><img src="img/image24.png" alt=""/></div>
        
        </div>
  <h4>5 <b>Sankassa:</b><br/>	
    Sankassa is an ancient city with mysterious history also Buddhist source claims that it was thirty leagues from Savatthi. King Ashoka developed this place and established Pillars of Ashoka after the Mahaparinirvana of Gautama Buddha. Among the pillars Elephant capital is still same. He also had built a temple and a stupa in the reminiscence of Buddha. The temple is stands still whereas we can witness the ruins of a stupa.
    
    In the modern times we can find many ruins of old monasteries and Buddhist monuments. A temple was constructed on the spot where the Buddha's right foot first touched the ground at Sankissa. There were ladders built by brick and stone by the ancestors in the memory of Buddha, discovered by the two Chinese pilgrims, Xuanzang and Faxian. But now it is almost being borrowed in the earth.
    
    </h4>
    <div className="tour_images">
      <div className="tour_images_image">
  <img src="img/image25.png" alt=""/></div>
  <div className="tour_images_image"><img src="img/image26.png" alt=""/></div>

</div>
<h4>6 <b>Vaishali:</b>	<br/>
    Now, Vaishali is an archaeological site in Bihar,India. It is considered as one of the first example of a republic around in 6th century as it was the capital city of Vajjian Confederacy. According to Buddhists sources Gautama Buddha preached his last sermon here before his death. This place has maintained the Pillars of Ashoka very well. Vaishali was a very large, crowded, rich and prosperous city at the time of Buddha and he often visited this place on many occasions. There were an equal number of lotus ponds and 7,707 pleasure grounds. The Chinese explorers, Xuanzang and Faxian visited this site and suggested other pilgrims to visit.
    </h4>
  <div className="tour_images">
    <div className="tour_images_image">
<img src="img/image27.png" alt=""/></div>
<div className="tour_images_image"><img src="img/image28.png" alt=""/></div>

</div>
    </section>
    </>
  )
}
