import React from 'react'
import { Link} from 'react-router-dom'
import "./style.css"

export default function Header() {
  return (
    <>
    <header>
      <section className="wrapper header_part">
        <div className="logo_img_name">
          <Link preventScrollReset="true"to="/">
            <div className="logo_img">
              <img src="img/logo.png" alt="" />
            </div>
            <h1 className="logo_display_1">Rigzin Dechhen Chhoeling Chuglag Khang</h1>
            <h1 className="logo_display">RDCCK</h1>
          </Link>
        </div>
        <div className="main_menu">
          <ul>
            <li className=" menu_topic"><Link preventScrollReset="true" to="/">Home</Link></li>
            <li className="menu_topic"><Link preventScrollReset="true" to="/aboutus">About Us</Link></li>
            <li className="menu_topic"><Link preventScrollReset="true" to="/projects">Projects</Link></li>
            <li className="menu_topic"><Link preventScrollReset="true" to="/services">Services</Link></li>
            <li className="menu_topic"><Link preventScrollReset="true" to="/tour">Tour</Link></li>
            <li className="menu_topic"><Link preventScrollReset="true" to="/supprtus"> Support Us </Link></li>
            <li className="menu_topic"><Link preventScrollReset="true" to="/signup">Sign up</Link></li>
            <li className="menu_topic"><Link preventScrollReset="true" to="/login">Login</Link></li>
            
            
          </ul>
          
          <div className="dropdown">
            <li className="signup_top display_donate"><Link preventScrollReset="true"to="/supprtus"> Support Us </Link></li>
            <li className="signup_top display_donate"><Link preventScrollReset="true"to="/signup">Sign up</Link>  </li>
            <button
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <div className="menu_bar" id="menu_bar">
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
              </div>
            </button>
            <ul className="dropdown-menu">
              <Link preventScrollReset="true"className="dropdown-item" to="/">Home</Link>
              <Link preventScrollReset="true"className="dropdown-item" to="/aboutus">About Us </Link>
              <Link preventScrollReset="true"className="dropdown-item" to="/projects"
                >Charitable Project
              </Link><Link preventScrollReset="true"className="dropdown-item" to="/services">Services</Link>
              <Link preventScrollReset="true"className="dropdown-item" to="/tour">Tour</Link>
              <Link preventScrollReset="true"className="dropdown-item" to="/signup">Sign up </Link>
              <Link preventScrollReset="true"className="dropdown-item" to="/login">Login </Link>
            </ul>
          </div>
        </div>
      </section>
    </header>
    </>
  )
}
