import React from 'react'
import "./style.css"
export default function Aboutus() {
  return (
    <>
    <section className="wrapper details_part_aboutus">
      <h1>Rigzin Dechhen Chhyoeling Chuglag Khang</h1>
      <div className="rdcck_logo"><img src="img/logo.png" alt="" /></div>
      <h5>
        <b>RDCCK, Who we are? </b><br />
        RDCCK is a practical school run by Buddhist monk that is responsible for
        teaching and training Buddhism, Philosophy, Buddhist Arts, culture,
        rituals, yoga and retreat, healing, astrology, modern education
        including IT and Television programs and also it's operating system. It
        has 4+1 years practical base course on Buddhism, IT and Buddhist Media.
        Students will have opportunity to learn and practice everything free of
        cost. School also provides free food, acommodation, books, stationary
        and medical facility in emergency. According to Buddhist monastic
        tradition monastic school manages all the expenses through generious
        donors, sponsor memebers, devotees and supports.

        <br />
        <div className="guru"><img src="img/guru_1.png" alt="" /></div>
        <span className="side">
          <div>
            <b>About founder</b><br />
            Venerable Khen Rinpoche Sonam Gyurme is a founder of several
            organizations related with Buddhist educeducation, culture,
            meditation and Buddhist media etc.Khen Rinpoche belong to those family who are leading Himalaya Buddhism from ancient times to Nepal.
It is believed that Venerable is a monk 45th Generation from Bangdel Monastic monk family which
mainly is situated in Kavre district of Nepal. Khen Rinpoche has taught various
            subjects such as Buddhist philosophy, meditation and so on in many
            societies, at his dharma centers, schools and other private
            colleges. Venerable speaks English, Tibetan, Hindi and Nepali and
            Ven. Khen Rinpochela is welknown among people for his excellent
            teaching, meditation guidence, friendly behaviour, love and
            kindness.
            <br />
          </div>
        </span>

        <div className="img_topic_scl">
          <b>Our other organizations:</b><br />
          <img src="img/Rigzin Dongag logo2.jpg" alt="" />
          <div className="topic_scl">
            <b>Rigzin Dongag Shedrub Chhoekhor Ling</b>
          </div>
          <br />
          RDSCL is a monastic college started from January 2022 situated at
          Jorpati near by world heritage site Boudha Stupa, Kathmandu, Nepal. It
          offers 9 years course on Buddhist studies and practices based on
          Theravada, Mahayana and Vajrayana school. Everything including
          education, food, acommodation and books are free of cost are offered
          to monks. <br />

          In future monastic college has plan to offer various course on
          Animation designing, Film and Documentary etc. to product exllent
          students -monks who can contribute vital role to make series of
          Buddha's life story and other life story of Bodhisatwas in animation
          and normal documentary.
          <br />This year only students from RDCCK complited class VIII with one
          year retreat has been studying from next years we are planning to take
          more students from other souces. <br />
          <img src="img/BTMC FINAL LOGO .png" alt="" />
          <div className="topic_scl">
            <b>Buddhist Teaching & Meditaion Centre</b>
          </div>
          <br />
          Buddhist Teaching & Meditation Centre was stablished on 2003 at Mhepi, Kathmandu, Nepal under the guidence of our Venerable Khen Rinpoche Sonam Gyurme Guru.
<br/>
It offers various courses of Buddhist teachings and meditation to all lay practitioners and students. BTMC has run many camp courses on various subjects like philosophy, Retreat, language and grammar and specially Buddhist strology in center and many district of Nepal.
<br/>
It also take devotees  pilgrimage tours to Nepal, India and other Buddhist counties and offers specific teaching based on pilgrimage sites, or any require subjects. 
<br/>
During pilgrimage tour it manages quality services of food, acommodation and vedio documentary of recent visiting activities and puja and meditation.
<br/>
Along with more than 20 years experience of providing perfect leadership in teachings and pilgrimage tours Venerable Khen Rinpoche has now special plan for pilgrimage tour programs with live broadcasting all important activities. 

          <br />
          <img
            src="img/Mahaboudhi Documentary & Film Production Foundation (MDFPF) Logo.jpg"
            alt=""
          />
          <div className="topic_scl">
            <b>Mahabodhi Documentary & Film Production Foundation</b> <br />
          </div>
          MDFPF is fully dedicated to serve people worldwide through Buddhist media. It develops audio visual teaching, documentaries and broadcast in diffirent languages in Satellite TV, IP TV and social media.
          <br />
          It was established in 2013 in initiative of Ven. Khen Rinpoche Sonam Gyurme with the objective of making normal and animation documentaries and films on Buddha, Guru Padma Samvawa and other masters.
          <br/>
          This is a non-profitable Buddhist media organization where people from all over the world can contribute supports by providing IT and media equiptments such as photo and video camera, computer, recording equiptments. Interested one can take various membership such as general sponsor membership, life time sponsor membership etc.   <br/>

        <div className="dharma">  <img src="img/Dharma TV small in size .png" alt="" /></div>
          <div className="topic_scl"><b>Dharma Television HD </b></div>
          This is the fisrt Buddhist satellite TV registered in 2019 in Nepal. It broadcasts 24 hours teachings, talks, Buddhist news and many more. Any one from any country can watch this tv on IP TV, Cable TV, Satellite TV and social media too.
           <br /> 
           This satellite television is very expensive dharma projects where more than 50 paid full time  staffs and 20 volunteer part time staffs works for various post and seriveces.
           <br/><br/>
           In coming future Dharma Television HD will be planing to organize  worldclass reality shows, awarding function and many more  such as 1. International Dharma Awards   2.Miss Vrikuti Tama (Dharma Beauti pageant    3. Voic of Dharma  4. Quistion Of Million .
           <br/>
           Dharma Television HD and BTMC are  jointly running "DHARMA IDOL CAMPAIGN" to serve worldwide practitioners  and masters with dharma, practices and Buddhist media. <br/><br/>
           Under this campaign we are jointly  organizing a "GLOBAL HOME RETREAT CAMPAIGN" where avery one in a family at house can practice various meditation and also learn puja, chanting, self- healing for health and many more. <br/>
           Practitioners, good wishers or supporters by paying  charges one can become member.
           
 <br/><br/>
 Dharma Idol Member will receive various services of puja, practices, teachings and broadcasting messeges on Dharma TV on varios occation.
<br/>      <br/>     
          Click here to visit website: <a className='link' href="http://dharmatelevision.tv/"> http://dharmatelevision.tv/ </a><br />
          Click here to download android mobile app: <a className='link' href="https://play.google.com/store/apps/details?id=com.lpt.dharmatv "> dharma tv</a>  <br />
          <b>Dharma Idol Campaign </b> <br />
          This in an international campaign jointly run by Dharma Television HD
          and BTMC. Main objective of this campaign is to guide people through
          dharma and dharma media and offer perfect guidence on the path of
          Enlightenment as well as they serve through teachings, meditation,
          healing and puja etc. It provide international platform to share
          social and religious messeges each others.
          Practitioners, good wishers or supporters by paying following charges
          one can become member. <br/>
          1. Dharma Idol Sponsor Membership charge for one year $100 USD <br/>
          2. Dharma Idol Decade Sponsor Member for ten years $1000 USD <br/>
          3. Dharma Idol Gem Sponsor Member for life time $5,000 USD <br/>
          Dharma Idol Member will receive various services of puja, practices, teachings
          and broadcasting message’s on Dharma TV on various occasions.
        </div>
      </h5>
    </section>
    </>
  )
}
