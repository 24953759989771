import Header from './mycomponents/Header';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from './mycomponents/Home';
import Aboutus from './mycomponents/Aboutus';
import Footer from './mycomponents/Footer';
import Projects from './mycomponents/Projects';
import Services from './mycomponents/Services';
import Tour from './mycomponents/Tour';
import Nepaltour from './mycomponents/Nepaltour';
import Indiatour from './mycomponents/Indiatour';
import Monastic from './mycomponents/Monastic';
import Support from './mycomponents/Support';
import Retreat from './mycomponents/Retreat';
import Temporary from './mycomponents/Temporary';
import Signup from './mycomponents/Signup';
import Login from './mycomponents/Login';
import ScrollToTop from './mycomponents/ScrollToTop';
function App() {
  return (
    <>
     <Router >
     <ScrollToTop/>
    <Header/>
    <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/aboutus" element={<Aboutus />} />
        <Route exact path="/monastic" element={<Monastic />} />
        <Route exact path="/projects" element={<Projects />} />
        <Route exact path="/services" element={<Services />} />
        <Route exact path="/tour" element={<Tour />} />
        <Route exact path="/nepaltour" element={<Nepaltour />} />
        <Route exact path="/indiatour" element={<Indiatour />} />
        <Route exact path="/supprtus" element={<Support />} />
        <Route exact path="/retreat" element={<Retreat />} />
        <Route exact path="/temporary" element={<Temporary />} />
        <Route exact path="/signup" element={<Signup />} />
        <Route exact path="/login" element={<Login />} />
      </Routes>
      <Footer/>
    </Router>
    </>
  );
}

export default App;
