import React from "react";
import "./style.css";
import { Link } from 'react-router-dom'


export default function Home() {
    const style = {
        overFlow: "hidden"
    }
  return (
    <>
      <div className="slider">
        <div
          id="carouselExampleIndicators"
          className="carousel slide"
          data-bs-ride="carousel"
        >
          <div className="carousel-indicators">
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="0"
              className="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="2"
              aria-label="Slide 3"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="3"
              aria-label="Slide 4"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="4"
              aria-label="Slide 5"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="5"
              aria-label="Slide 6"
            ></button>
          </div>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img src="slider/1.jpg" className="d-block w-100" alt="..." />
            </div>
            {/* <div className="carousel-item">
              <img src="slider/2.jpg" className="d-block w-100" alt="..." />
            </div>
            <div className="carousel-item">
              <img src="slider/3.jpg" className="d-block w-100" alt="..." />
            </div> */}
            <div className="carousel-item">
            <img src="slider/2.jpg" class="d-block w-100" alt="..." />
            </div>
            <div className="carousel-item">
              <img src="slider/3.jpg" className="d-block w-100" alt="..." />
            </div>
            <div className="carousel-item">
              <img src="slider/4.jpg" className="d-block w-100" alt="..." />
            </div>
            <div className="carousel-item">
              <img src="slider/5.jpg" className="d-block w-100" alt="..." />
            </div>
            <div className="carousel-item">
              <img src="slider/6.jpg" className="d-block w-100" alt="..." />
            </div>
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="prev"
          >
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="next"
          >
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
      <section className="video_details">
        <div className="wrapper details_video">
          <div className="vid_detail container">
            <div>
              <h1>RDCCK, Who we are?</h1>
              <h5>
                RDCCK is a practical school run by Buddhist monk that is
                responsible for teaching and training Buddhism, Philosophy,
                Buddhist Arts, culture, rituals, yoga and retreat, healing,
                astrology, modern education including IT and Television programs
                and also it's operating system.
              </h5>
              <button>
                <Link preventScrollReset="true"to="/aboutus">
                  More About Us <i className="fa-solid fa-arrow-right"></i>
                </Link>
              </button>
            </div>
          </div>
          <div className="yt_video container">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/h9uktnaSER4"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullscreen="true"
            ></iframe>
          </div>
        </div>
      </section>
      <section className="video_details">
        <div className="wrapper details_video">
          <div className="vid_detail container">
            <div>
              <h1>RDSCL, Who we are?</h1>
              <h5>
              RDSCL is a non-profitable traditional Buddhist Monastic College dedicated to offer all Buddhist curriculums including 3 years meditation practice course to monks and nuns with residence, food and health service facilities for free of cost.

              </h5>
              <button>
                <Link preventScrollReset="true"to="/aboutus">
                  More About Us <i className="fa-solid fa-arrow-right"></i>
                </Link>
              </button>
            </div>
          </div>
          <div className="yt_video container">
          <iframe width="560" height="315" src="https://www.youtube.com/embed/J9a7BQJfpdI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullscreen="true"></iframe>
          </div>
        </div>
      </section>
      <section className="video_details">
        <div className="wrapper details_video">
          <div className="vid_detail container">
            <div>
              <h1>nunnery monastic college</h1>
              <h5>
              Venerable Khen Rinpoche has a specific education
project to support female by leading them to higher
Buddhist education and practices under one roof.Generally, in Asia female are dominated by male in the
society, many female without any reason they are
limited at house and they have no right to make their
choice for personal life.In the past few years many young and middle aged
female has made special request to support them with
the guidance of Buddhism and Buddhist studies and
practices thus Khen Rinpochela has a specific education
project of nunnery college where female can study upto
MA in Buddhism and practices for free of cost.
              </h5>
              <button>
                <Link preventScrollReset="true"to="/aboutus">
                  More About Us <i className="fa-solid fa-arrow-right"></i>
                </Link>
              </button>
            </div>
          </div>
          <div className="yt_video container">
          <iframe width="560" height="315" src="https://www.youtube.com/embed/hTaDO3qCqaI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
        </div>
      </section>
      <section className="mission_vision">
        <h1>Our mission and vision</h1>
        <div className="wrapper misson_IT">
          <div className="why_IT">
            <a href="">
              <img src="img/person_2.jpg" alt="" />
              <div className="description">
                <h3>Why IT, Television and Film trainings ?</h3>
                <h5>
                  To the era of Info-Tech, IP TV, Social media, Film and
                  documentary We RDCCK without personal attachment just take
                  benefits from all those new technologies to pass voice of
                  Buddhas to the entire world fastest and effective way and make
                  Buddha Dharma a digital path as an easy way of usderstanding
                  Self-Buddha nature and unltimate truth.
                </h5>
              </div>
            </a>
          </div>
          <div className="why_IT">
            <a>
              <img src="img/8.jpg" alt="" />
              <div className="description">
                <h3>Mission of RDCCK</h3>
                <h5>
                  Our mission is to produce excellent trained and qualified
                  monks, nuns and general students on modern and traditional
                  Buddhist education , IT, Television, documentery and Film
                  industry field.
                </h5>
              </div>
            </a>
          </div>
        </div>
      </section>
      <section className="video_details video_2">
        <div className="wrapper details_video details_video_1">
          <div className="yt_video  yt_video_1 container image_support">
            <img width="560" height="315" src="img/side.jpg"></img>
          </div>
          <div className="vid_detail vid_detail_1 container">
            <div className="left_supp">
              <h1>Support Us</h1>
              <h5>
                RDCCK is a practical school run by Buddhist monk that is
                responsible for teaching and training Buddhism, Philosophy,
                Buddhist Arts, culture, rituals, yoga and retreat, healing,
                astrology, modern education including IT and Television programs
                and also it's operating system.
              </h5>
              <button>
                <Link preventScrollReset="true"to="/supprtus">
                  Support Us <i className="fa-solid fa-arrow-right"></i>
                </Link>
              </button>
            </div>
            <div className="right_supp">
              <img src="img/babu_1.png" alt="" />
            </div>
          </div>
        </div>
      </section>

      <section className="mission_vision">
        <h1>Cheritable Projects</h1>
        <div className="wrapper charatable_IT">
          <div className="charatable_projects">
            <Link preventScrollReset="true"to="/monastic">
              <img src="img/project_1.jpg" alt="" />
              <div className="description">
                <h3>
                  Monastic school contruction at Barahithan, Nuwakot, Nepal
                </h3>
              </div>
            </Link>
          </div>
          <div className="charatable_projects">
            <Link preventScrollReset="true"to="/temporary">
              <img src="img/project_2.jpg" alt="" />
              <div className="description">
                <h3 style={style}>
                  Temporary Monastic college construction at Jorpati,
                  Kathmandu,Nepal
                </h3>
              </div>
            </Link>
          </div>
          <div className="charatable_projects">
            <Link preventScrollReset="true"to="/retreat">
              <img src="img/one (9).jpeg" alt="" />
              <div className="description">
                <h3>Retreat Centre at Kakani, Nuwakot,Nepal</h3>
              </div>
            </Link>
          </div>
        </div>
      </section>
      <section className="image_ads">
        <img src="img/unname.jpg" className="d-block w-100" alt="..." />
      </section>

      <section className="image_ads">
        <Link preventScrollReset="true"to="/tour">
          {" "}
          <img
            src="img/tour.jpg"
            className="d-block w-100"
            alt="..."
          />
        </Link>
      </section>
      <section className="our_products">
        <h1>Excellent Students of our school</h1>
        <div className="wrapper products_img_details">
        <div className="product">
            <img src="img/person_6.jpg" alt="" />
            <p>
              Rigzin Wangchhyug Tamang <br />
              Graphic & Motion Desiger
            </p>
          </div>
          
          <div className="product">
            <img src="img/person_2.jpg" alt="" />
            <p>
              {" "}
              Rigzin Tendar Tamang <br />
              MCR Assistant
            </p>
          </div>
          <div className="product">
            <img src="img/person_5.jpg" alt="" />
            <p>
              Rigzin Ngima Hoisher Tamang <br />
              Video Editor
            </p>
          </div>
          
          <div className="product">
            <img src="img/person_4.jpg" alt="" />
            <p>
              {" "}
              Rigzin Kalsang Tamang <br />
              MCR
            </p>
          </div>
          <div className="product">
            <img src="img/person_1.jpg" alt="" />
            <p>
              Rigzin Chhyoedag Tamang <br />
               Camera Man
            </p>
          </div>
          <div className="product">
            <img src="img/person_3.jpg" alt="" />
            <p>
              Samten Lhundub Tamang <br />
              MCR Assistant
            </p>
          </div>
          
          <div className="product">
            <img src="img/person_7.jpg" alt="" />
            <p>
              Rigzin Namgyal Tamang <br />
               Typist
            </p>
          </div>
          <div className="product">
            <img src="img/jin.jpg" alt="" />
            <p>
              Rigzin Jigme Wanchhyug Tamang <br />
               Motion Designer
            </p>
          </div>
        </div>
      </section>
      <section className="our_products">
        <h1>Yeshe Gyasto Little Warior</h1>
        <div className="wrapper products_img_details">
          <div className="product">
            <img src="rdcck_images/IMG_20220829_134419.jpg" alt="" />
          </div>
          <div className="product">
            <img src="rdcck_images/IMG_20220825_182336.jpg" alt="" />
          </div>
          <div className="product">
            <img src="rdcck_images/IMG_20220825_182916.jpg" alt="" />
          </div>
          <div className="product">
            <img src="rdcck_images/IMG_20220825_184919.jpg" alt="" />
          </div>
          <div className="product">
            <img src="rdcck_images/IMG_20220902_210909.jpg" alt="" />
          </div>
          <div className="product">
            <img src="rdcck_images/IMG_20220831_201604.jpg" alt="" />
          </div>
          <div className="product">
            <img src="rdcck_images/IMG_20220823_195507.jpg" alt="" />
          </div>
          <div className="product">
            <img src="rdcck_images/IMG_20220903_200108.jpg" alt="" />
          </div>
        </div>
      </section>
    </>
  );
}
