import React from 'react'
import "./style.css"
export default function Nepaltour() {
    const style = {
        textAlign : "center",
        margin : "20px "
    }
  return (
    <>
        <h1 style={style}>Tour Packages</h1>
    <section className="pacakages wrapper">
      
      <div className="package">
  
       <h3> 1O DAYS NEPAL PILGRIMAGE TOURS AND PRACTICES </h3>
        <h4>
Day 1 Boudha, Swoyambhu, Patan pigrimage <br/>
Day 2, 3 and 4 Lumbini, Kudan, Kapilvastu, Niglihawa, Gotihawa, Devdaha<br/>
Day 5 Chhumig Jyangchhub<br/>
Day 6 Kathmandu local pilgrimages<br/>
Day 7 Yharinag, Namobuddha<br/>
Day 8 Pharping, Taudaha <br/>
Day 9  Vajra Varahi, Yampi Bihar, Thousang Buddha, Patan Darwar Square<br/>
Day 10 Thamel, New road farewell <br/>
</h4>
      </div>
      <div className="second_package">
     <h3>5 DAYS NEPAL  PILGRIMAGE TOURS AND PRACTICES</h3>   
<h4>
Day 1 Yarinag and Namo Buddha <br/>
Day 2 Pharping <br/>
Day 3 Chhumig Jyangchhub<br/>
Day 4 Nagarjuna and Godawari<br/>
Day 5 Inside Kathmandu, Lalitpur and Bhaktapur pilgrimage<br/>
</h4>
      </div>
    </section>
    <section className="pacakages wrapper">
      
      <div className="package">
  
       <h3>5 NIGHTS 6 DAYS MUSTANG PILGRIMAGE AND POKHARA  HOLIDAY TOURS  </h3>
        <h4>
        Day 1 & 2  Pokhara tours  <br/>
Day 3,4,5, Mustang, Muktinath, Beni<br/>
Day 6 back to Kathmandu<br/>

</h4>
      </div>
      <div className="second_package">
     <h3>10 DAYS NEPAL PILGRIMAGE AND HOLIDAYS TOURS</h3>   
<h4>
Days 1,2,3 Lumbini, Kudan, Kapilvastu, Niglihawa, Gotihawa, Devdaha<br/>
Day  4 Chitwan<br/>
Day 5, 6 Pokhara<br/>
Day 7 Chhuimg Jyangchhub<br/>
Day 8 Chandragiri<br/>
Day 9, 10 Kathmandu, Vaktapur, Lalitpur<br/>

</h4>
      </div>
    </section>
    <section className="body_part wrapper">
        <h1>Nepal Pilgraimage</h1>
        <h4>Nepal is a country rich in Natural Resources and boasts a number of cultural and religious sites. It is the only country where the  Lord Shakya Muni Buddha, Kakru Chanda Buddha, Kanak Muni Buddhas were born. It is the best and  popular destination for peace and wisdom lovers.
            There are a number of religious heritage and pligrimage including secret lands where you receive peace, aura, one pointness, inner energy of meditaion and lots of blessings of long life, sound health, wish fulfillment and also cleasing the negative karma. Once you visit pilgrimage site with profound masters who explain about pligrimage in details than you develop your faith and believe that you feel you will achive Boddhuhood for sure. <br/>
           <b>Very popular sites and pilgrimage are:</b> 
          </h4>
          <h4>1. <b>Boudha Mahachaitye:</b>	<br/>
            Bouddha Mahachaitye is the biggesgt stupa in Nepal.
             It is a  located in Kathmandu valley,  It is also enlisted as one of the most beautiful sites in World Heritage site. Its unique structure attracts many tourists from around the world. Visiting the stupa in the morning or the evening is absolutely surreal. It is believed that once we visite the stupa we will achieve an enlightenment in future.
            </h4>
            <div className="tour_images">
                <div className="tour_images_image">
            <img src="img/image1.png" alt=""/></div>
            <div className="tour_images_image"><img src="img/image2.png" alt=""/></div>
        
        </div>
        <h4>2 <b>Swayambhu Stupa:</b>	<br/>
            Swayambhu Stupa is one of the oldest stupa in Nepal and the world.
            
            The stupa premises has a lot of other temples, chaityas and ancient monuments. The Santipura,  hundreds of other chaitya, Harati Temple are some important sites that has ancient history and value to visit. . 
            </h4>
            <div className="tour_images">
                <div className="tour_images_image">
            <img src="img/images3.png" alt=""/></div>
            <div className="tour_images_image"><img src="img/images4.png" alt=""/></div>
        
        </div>
        <h4>3 <b>Namo Buddha:</b>	<br/>
            Namo Buddha is very famous among many pilgrims.
            It is the place where the Sakyamuni in his previous life practiced compassion and empathy as a Bodhisattva heading towards the path to enlightenment. 
            Bodhisattva Nyingtobchen initially offered himself to the hungry tigress and her five cubs because she lacked the  energy, tigress  could not kill him, Optionless, the mahasattva sliced himself piece by piece  and offered the flesh the tigress. </h4>
            <div className="tour_images">
                <div className="tour_images_image">
            <img src="img/image5.png" alt=""/></div>
            <div className="tour_images_image"><img src="img/image6.png" alt=""/></div>
        
        </div>
        <h4>4 <b>Lumbini:</b>	<br/>
            Lumbini is the birthplace of Sakyamuni Buddha. The Mayadevi temple complex is one of the major attraction of this place. Here, visitors can explore the various monasteries that have been built by various Buddhist countries of Asia and Europ. The monasteries that are built here by various nations resemble the style and architecture of the monasteries of the home country. 
            The historical Mayadevi Temple, Ashoka Pillar, Peace Pagoda, Lumbini Garden, Chinese Temple, Thai Monastery, Burmese Monastery are some sites that attracts pilgrims. 
            
            Near by here Nigli Hawa and Goti Hawa are the most important site where Kanaka Muni Buddha and Kakruchhanda Buddha was born.
            
            Kapilvastu, Kudan and Devdaha are very historical and important sites near by Lumbini. 
            </h4>
            <div className="tour_images">
                <div className="tour_images_image">
            <img src="img/image7.png" alt=""/></div>
            <div className="tour_images_image"><img src="img/image8.png" alt=""/></div>
        
        </div>
        <div className="tour_images">
          <div className="tour_images_image">
      <img src="img/image9.png" alt=""/></div>
      <div className="tour_images_image"><img src="img/image10.png" alt=""/></div>
  
  </div>
  <h4>5 <b>Halesi:</b>	<br/>
    Maratika Cave at Halesi is an important Buddhist pilgrimage secret land. At this place, Buddha Amitabha gave a discourse on the eighteen Tantras of long life. Dakini Sangwa Yeshe recorded the teachings and hid these those as Terma at Maratika. Later Guru Padma Sambhava received the teachings and while he practicing, Buddha Amitayus then appeared in the sky and gave initiations to the Guru and Mandarava. Then they attained the Vidyadhara of longevity. Pilgrims often visit this Maratika Cave specially to receive the long life blessing and  a accomplishment. 
    </h4>
    <div className="tour_images">
      <div className="tour_images_image">
  <img src="img/image11.png" alt=""/></div>
  <div className="tour_images_image"><img src="img/image12.png" alt=""/></div>

</div>
<h4>6 <b>Pharping:</b>	<br/>
  Pharping is located in the South of Nepal and it is a place where Guru  Padma Sambhava achieved enlightenment Mahamudra. This place is blessed with Guru Rinpoche and there are two main practice caves in Pharping Asura Cave and Yanghleso cave.</h4>
  <div className="tour_images">
    <div className="tour_images_image">
<img src="img/image13.png" alt=""/></div>
<div className="tour_images_image"><img src="img/image14.png" alt=""/></div>

</div>
<h4>7 <b>Chhairo gompa:</b>	<br/>
  Chhairo Monastery is the first monastery of the Nyingma school of Tibetan Buddhism. It is a part of the present-day Mustang district and was established in the 16th century. The frescos are painted in sMon and Gya Bris styles and are predominantly in the sMon Bris style with elements of U Bries and even Bal Bris. However, the original frescos got washed away with time. Sakyamuni is in the central alter of the monastery there. </h4>
  <div className="tour_images">
    <div className="tour_images_image">
<img src="img/image15.png" alt=""/></div>
<div className="tour_images_image"><img src="img/image16.png" alt=""/></div>

</div>
    </section>
    </>
  )
}
