import React from 'react'
import "./style.css"

export default function Support() {
    const style = {
        textAlign : "center"
    }
    const secondstyle = {
        textAlign : "center",
        marginButtom : "30px"
    }
  return (
    <>
        <section className="wrapper statement">
        <section className="through_wallet western">
        <img src="img/western.png" alt="" />
        <h3>
         <b>For Western Union, IME and others </b> <br />
          Name: <b>Sonam Gyurme Tamang</b> <br />
          Adress: <b>Boudha Circle, Boudha-6, Kathmandu,Nepal </b> <br />
          Mobile Nomber: <b> 977-9849118562</b> <br />
          Email: <b>buddhistlecturer@gmail.com</b>  <br />
        </h3>
      </section>
      <div className="through_wallet">
        <h1>Bank Account Details</h1>
        <div className="wallet">
          <div className="khalti_wallet">
            <h3 style={secondstyle}>Beneficiary Bank account:</h3>
            <h4>
             <b> Prabhu Bank </b><br/>
              Beneficiary Name: <b>Dechhen Chhoeling Louke Boudha Gumba</b><br />
              SWIFT: <b>PRVUNPKA</b> <br/>
              A/C No.: <b>0400-1439-5580-0011</b> <br/ >
              Swaymabhu Branch,KMC-15, Kathmandu
            </h4>
          </div>
        </div>
      </div>
    </section>
    <section className="wrapper  statement">
      <section className="signup_sponsored through_bank">
        <h3>Sponsor's details</h3>
        <div className="topic_name">
          <p>Name :</p>
          <input type="text" name="" id="" placeholder="enter your name" />
        </div>
        <div className="topic_name">
          <p>Email :</p>
          <input type="text" name="" id="" placeholder="enter your mail" />
        </div>
        <div className="topic_name">
          <p>Continent :</p>
          <select>
            <option>Asia</option>
            <option>Europe</option>
            <option>America</option>
            <option>South America</option>
            <option>North America</option>
            <option>Australial</option>
            <option>Antarctica</option>
          </select>
        </div>
        <div className="topic_name">
          <p>Address :</p>
          <input type="text" name="" id="" placeholder="enter your address" />
        </div>
        <div className="topic_name">
          <p>Support for :</p>
          <select>
            <option value="">Yeshe Gyatso</option>
            <option value="">Indivisual monk per month</option>
            <option value="">Intdivisual monk per year</option>
            <option value="">Food</option>
            <option value="">stationary</option>
            <option value="">Medical care</option>
            <option value="">Staff expenses</option>
            <option value="">Puja and practices</option>
            <option value="">RDCCK monastic schooll construction</option>
            <option value="">Temporary monastic college construction</option>
            <option value="">Meditation center construction</option>
            <option>IT equiptments</option>
            <option >Pilgraimage tour</option>
            <option>Buddhist Television Programs</option>
            <option>Pilgrimage documentary and Films</option>
            <option>Mahabodhi Documentary & Film Production Foundation</option>
          </select>
        </div>
        <div className="topic_name">
          <p>Date of birth :</p>
          <input type="date" />
        </div>

        <button className="submit_button" type="button">Submit</button>
      </section>
      <div className="through_wallet wallet_down">
      <h1>Support Us through Wallet</h1>
      <div className="wallet">
        <div className="khalti_wallet">
          <h3>Support us through <br /> khalti</h3>
          <img src="img/khalti.png" alt="" />
        </div>
        <div className="esewa_wallet">
          <h3>Support us through <br /> esewa</h3>
          <img src="img/esewa.png" alt="" />
        </div>
      </div>
    </div>
      
      
    </section>
    </>
  )
}
