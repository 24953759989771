import React from 'react'
import "./style.css"

export default function Services() {
    const style = {
        textAlign : "center",
        margin : "20px ",
        paddingButton : "10px"
    }
    const h2style = {
        textAlign : "center",
        margin : "30px ",
        paddingButton : "10px"
    }
    const h4style = {
        margin : "20px 0px"
    }
  return (
    <div>
          <h1 style={style}>Our Services</h1>
    <section className="total_services wrapper">
        <div className="services_image">
        <img src="img/services_1.png" alt=""/>
    </div>
    <div className="services_image">
        <h3>Dharma Teachings And Meditation:         </h3>
       <h4> • Applied Buddhism  <br/>
        • Training on various meditation <br/>
        • Life story of Lord Buddha <br/>
        • Preaching on the stages of death  related one . <br/>
        • Importance of daily puja and their benefits <br/>
        • Importance of  Human Life <br/>
        • Benefits and way  of chanting Mani, Vajra Guru and other mantras <br/>
        • Discourse on Kunsang Lhamo Shalung <br/>
        • Discourse on Vardo Thendol (Teaching on after life stage for leading to liberation and enlightenment.) <br/> 
        • Discourse on three jewels <br/>
        • Discourse on Gyalsey Laglen(A Boddhi Satwa's avtivities) </h4>

    </div>
    </section>
    <h1 style={style}>ASTROLOGICAL SERVICES</h1>
    <section className="total_services wrapper">
    <div className="services_image">
        <h4><b> 1.  Dasha Graha</b>   <br/>
            (Elemental Astrological prediction for life, health, business, job, career, relation, marriage, travel, study, luck and various obstacles.)
            </h4>
            <h4 style={h4style}> <b> 2.  Astrological consultance for land and  house </b> <br/>
    (astrological interpretation and purgation of land for the construction of new houses.)</h4>
            <h4> <b> 3. Astrological referral on abroad study and work</b></h4>
    
            <h4> <b> 4. Married Life astrological referral</b></h4>
    
            <h4> <b> 5. Domesticity astrological referral</b></h4>
    
            <h4> <b> 6. Astrological consultation on subjects of study</b></h4>
    
            <h4> <b> 7. Business referral</b></h4>
    
            <h4> <b> 8. Astrological advisory on investments</b></h4>
    
            <h4> <b> 9. Birth astrology  (natal chart)</b></h4>

    </div>
    <div className="services_image_1 ">
        <img src="img/services_5.png" alt=""/>
    </div>
    </section>
    

<div className="wrapper">
    <h2 style={h2style}><b>VARIOUS PUJA AND PRACTICES <br/> FOR HEALING, BUSINESS, CAREER, TREATMENT, RELATION,</b> </h2>
    <h4 style={h4style}><b > 1.  Sogchok and Gongchok</b>   <br/>
        Our monastery has daily schedule of Aarya Tara Puja in morning and Dharmapala puja of power in the evening. So if some one need continue puja for their health, business,  peace, happiness, whish fulfilment  and to solve various problems and obstacle of many sectors,  they can apply for Sogchog and Gongchog puja for 3 days, 7 days, 15 days, 1 months or more than that.
        </h4>
        <h4 style={h4style}> <b> 2.  Thunsum ,Shishe,  Donsur and Rangkek Obstacle Solving Puja:  </b> <br/>
            These puja is very important to perform once in a year. Accordingt to Elemental Astrology the negative energies of planet, elements and all imvisible sources impact our daily life, health careers so to block all the universal negative energy impacts and provide positives energies for health, relation, career and business these puja is performed.</h4>
        <h4 style={h4style}> <b> 3. Tse Thar Puja</b> <br/>
            Freeing fish, birds and animals for one’s long life and good health. • Lagug Puja : Puja done for mentally disturbed people. 
        </h4>

        <h4 style={h4style}> <b> 4. Apaarmitayu Buddha Puja and Sadhana</b> <br/>
            for person’s long-life by prayers and practice of Aparamitayu Buddha. 

        </h4>

        <h4 style={h4style}> <b> 5. Medicine Buddha Puja and Sadhana</b><br/>
            for one's better health.
        </h4>

        <h4 style={h4style}> <b> 6. AaryaTara Puja and Sadhaana</b><br/>
            for having a new baby , wealth and peace in the family. 

        </h4>

        <h4 style={h4style}> <b> 7. Urgen NorLha Puja and Sadhana</b><br/>
            for accomplishment, and acquisition and stablishment of wealth, peace and happiness. 
        </h4>



        <h4 style={h4style}> <b> 8. Torma Chhyasum </b><br/>
            For making peace the negative energies and mental diseases, astrological obstacles 

        </h4>

        <h4 style={h4style}> <b> 9. Sernying Dyudok puja</b><br/>
            to protect oneself from negative energies, and various obstacles. 

        </h4>
        <h4 style={h4style}> <b> 10. Gyapsi Puja</b><br/>
            A grand puja with 400 puja materials is done to avoid illness and for long-term attainment of good health, long life, wisdom and also to solve negative power and energies, this puja takes 1 whole day to be completed. 

        </h4>
        <h4 style={h4style}> <b> 11. Tonag Gosum Puja</b><br/>
            To get rid of sufferings, misfortune and bad dreams etc

        </h4>
        <h4 style={h4style}> <b> 12. Chhyangbu Gyachya</b><br/>
            To protect from evil spirit and negative energies and make peace and happiness. 

        </h4>
        <h4 style={h4style}> <b> 13. Riwo Sang  and Serkyem</b><br/>
            This holy smoke and nector offering is done to pay homage to all God and Goddesses and maintain peace and happiness. 
        </h4>
        <h4 style={h4style}> <b> 14. Solkha Dharmapaal Puja</b><br/>
            Done to clear all obstacle  and for attainment of work and success etc.. 

        </h4>
        <h4 style={h4style}> <b> 15. Dokpa Chedup Puja</b><br/>
            To destroy obstacle and for long life) 

        </h4>
        <h4 style={h4style}> <b> 16. Rapne Duiba and Rapne Gyepa</b><br/>
            Done during establishment of stupa, gompa and crafting of statues. 

        </h4>
        <h4 style={h4style}> <b> 17. Salang Puja</b><br/>
            Purification of land's negative energy as well as praying for land Lord (God) before building houses. 
        </h4>
        <h4 style={h4style}> <b> 18. Tsechu Chopa </b><br/>
            Sadhaana and worshipping Guru Padma Sambhawa which  is done every 10th Lunar day of  month  for long life,health, power and siddhis (acompleshment of all). 

        </h4>
        <h4 style={h4style}> <b> 19. Nyisu nga Khando Chhopa</b><br/>
            This is a practice of power basis in Machik Throma on every 25th Lunar day of elemental astrology  for siddhi and fulfillment of all wishes and to solve all the physical and phsycological problems. 
        </h4>


</div>
<section className="wrapper temporary">
    <div className="temporary_img">
        <img src="img/services_3.png" alt=""/>
        <img src="img/okey (2).png" alt=""/>
    </div>    
</section>
<div className="wrapper">
    <h2 style={h2style}><b>CHANTING SUTRAS</b> </h2>
    <h4 style={h4style}><b > 1.  Domang -Jungdue</b>   <br/>
        For health ,wealth and peace, this recitation is completed in one whole day by  4 lamas. 
        </h4>
        <h4 style={h4style}> <b> 2.  Nyithi:  </b> <br/>This is a sutra based on wisdom taught by Lord Buddha and it is recited for the attainment of enlightenment, honor, virtue and . There are three large sutras (books) which is completed in 3 days by 12 monks. </h4>
        <h4 style={h4style}> <b> 3. Bum</b> <br/>
            This is a pragya paramita sutra taught by Lord Buddha for ultimate truth and wisdom it has 16 pack sutras (Books) which is recited for the realization of ultimate truth, wisdom and for achievement of five paths and ten grounds of Bodhi Satwa to reach the land of Buddha. This  Pragya Paramita Sutra recitation is completed in  one day  by 48 lamas . . 
        </h4>

        <h4 style={h4style}> <b> 4. Kangyur</b> <br/>
            Kangyur is a translation of Tri-Pitaka a complete teaching of Lord Buddha and this sutras are recited for peace, happiness, virtue and to crossing five paths and ten ground of Bodhi Satwas to reach at the land of Buddha. recitation of this whole sutra more than 125 books is completed in one day by 375 monks.  

        </h4>

        <h4 style={h4style}> <b> 5. Tengyur</b><br/>
            Whole  scriptures of Buddha, completed  by 365 lamas in 1 day for happiness, peace, good lucks, success and enlightenment. 
        </h4>

        

</div>
<section className="wrapper temporary">
    <div className="temporary_img">
        <img src="img/services_6.png" alt=""/>
        <img src="img/services_7.png" alt=""/>
    </div>    
</section>
<div className="wrapper">
    <h2 style={h2style}><b>PRACTICES</b> </h2>
    <h4 style={h4style}><b > 1.  Ngyungne</b>   <br/>
        A one day fasting or 1 to 16 days fasting , sadhana and puja of Arya Lokeshwor  which is perform for cleaning negative deeds, thoughts, violence, inhuman activities and to achieve peace, happiness and enlightenment. 
 
        </h4>
        <h4 style={h4style}> <b> 2.  Choi – Throma Bhumtsok Ganachakra Mahapuja  One Hundred Thousand Feast Offering Puja:  </b> <br/>
            This puja can be either of 1 day, 5 day or 7 day in which bumtsog one hundred thousand times feast is offered to the god of wisdom and power to accumulate virtue and wisdom and destroy all negative thoughts and deed with the powerful blessing of Machik Throma a complete form of three luminous body of Buddha of three times and all direction. It needs more than 150 people to complete one  hundred thousand feast offering  within three days.  </h4>
        <h4 style={h4style}> <b> 3. Dorjee Phurba Dhupchen</b> <br/>
            This is 7 days Vajra  Kilaya great practice for power to clear all types of obstacle, illness, problems, and to enhance relation, business, power. It is a very dedicating powerful practice and puja and it needs more lamas and practitioners to complete with right procedure without making any incompletion and mistakes. Maha Yagya Program By performing this peace spreading Mahayagya world peace fire maha puja, it is believed that all the Gods and Goddesses will be pleased and in returns, they will heal all our illness and source of grievances. By performing this fire puja nature is purified and all positive energy will actively  spreaded to our society and beings thus things will come to our day to day life very positively and every one will be happy, peace, weathy, healthy, harmonious. 
        </h4>
</div>
<div className="wrapper">
    <h2 style={h2style}><b>PUJA FOR DESEASED ONES</b> </h2>
    <h4 style={h4style}><b > 1.  Sithro Puja </b>   <br/>
        Shitho Puja takes one full day to complete and this is very  important puja for living enlightened life and also important for the liberation and enlightenment of departed ones.  
 
        </h4>
        <h4 style={h4style}> <b> 2.  Dhechhoe -Kunchok Chidui , Thukjey Chenpo, Shitho Nelung </b> <br/>
            This puja is especially for liberation and enlightenment of departed soul. </h4>
        <h4 style={h4style}> <b> 3. Feast and Light offering  and Mani  Prayers</b> <br/>
            This is a short puja for liberation and enlightenment of departed soul
        </h4>
        <h4 style={h4style}> <b> 4. Duntsik Chyoga</b> <br/>
            This puja is done once in a week on 7th day  for death one till 49 days of passing away. 
        </h4>
        <h4 style={h4style}> <b> 5. Lho Chhoei</b> <br/>
            Annual prayers and puja for tshose who passed away . 
        </h4>
</div>
<h2 style={h2style}>  <b>FOR YOUR BISENESS AND CAREER </b></h2>
<div className="total_services wrapper" style={{marginTop:"10px"}}>
    <div className="services_image_2 ">
        <img src="img/services_8.png" alt=""/>
    </div>
    <div className="services_image">
   
    <h4>
        <b>
        1. Ugyen Norlha's Teasure Vase  <br/>
        2. Naga Vase <br/>
        3. Earth Treasure Vase <br/>
        4. Long Life Vase (not available now) <br/>
        5. Medicine Buddha Vase (not available now) <br/>
    </b>
    </h4>
</div>


</div>
<div className="wrapper">
    <h2 style={h2style}><b>FOR YOUR BISENESS AND CAREER</b> </h2>
    <h4 style={h4style}><b > 1.  Ugyen Norlha's Teasure Vase </b>   <br/>
        Treasure Vase aka Yangbum is an ancient remedy which can restore vitality and balance to the essence of the elements – earth, air, fire, water and space — that have been depleted by the pollutants and poisons in the environment .it has the power to magnetize wealth ,improve health ,remove obstacles to long life ,and pacify anger and warfare ,and compassion for increase wisdom all Traditionally, Treasure Vase is a must to kept in one’s home for own betterment. 
        </h4>
        <h4 style={h4style}> <b> 2.  Earth Teasure Vase :  </b> <br/>The Vase of the Life Essence of the Earth (Sa-Cho Bhumpa) is meant to bless and empower the earth where it is placed. It is a way of putting positive mind energy into the natural environment. The Vase protects the land from the ravages of earthquakes, floods, thunder and lightning. It is said the blessings from the Vase extend for fifteen miles around where the Vase is placed. Within this area people will experience good health , long life , wealth ,protection from evil ,and peace. Therefore, this Earth Treasure Vase is a must have empowered before construction of stupa, monastery, house and factories.  </h4>
        <h4 style={h4style}> <b> 3.  Naga Vase </b> <br/>
            According to Buddhism and Indic religions, Nagas are mystical aquatic beings that live under the earth or in oceans, lakes, rivers and wells. Known for their immense mystical powers, they bring rain for farmers, enhance fertility in married couples, as well as prevent natural calamities. Yet, Nagas are themselves vulnerable to various difficulties mainly caused through contamination of their natural habitat in form of water pollution, deforestation, etc.  So this ritual involves dissolving of Naga vase filled with medicinal herbs and substances, precious stones, blessed pills and printed mantras as an offering into water bodies such as ocean and lakes. This offering of Naga Vase Brings good health , prosperity, and fame, and is especially helpful in curing skin diseases caused by contamination of our natural environment and water bodies.   
        </h4>

        

        

</div>
<div className="wrapper">
    <h2 style={h2style}><b>FOR HEALTH, CAREER, BUSINESS, PROTECTION AND MORE</b> </h2>
    <h4 style={h4style}><b > 1.  Dirghayu Chakra (Long-Life cycle) </b>   <br/>
        Dirghayu Chakra (Long-Life cycle): This amulet is for those who are going through minor or severe life threatening obstacles. 
        </h4>
        <h4 style={h4style}> <b> 2.  Swaasthya Chakra (Health Cycle)   </b> <br/>People who are always prone to accidents and diseases must wear this amulet to avoidpossible threats to one's health and to attain flourishing life.  </h4>
        <h4 style={h4style}> <b> 3.  Bhaagya Chakra (Fortune Cycle)  </b> <br/>
            This amulet is really effective for those who constantly encounter with misfortune and adversity. This amulet will bring good lucks and prosperity in their life and cease all negative impacts.   
        </h4>
        <h4 style={h4style}><b > 4. Adhikaar Chakra (Power Cycle)  </b>   <br/>
            If the person is always failing on accomplishing important task, be it personal or official work, by wearing this Power Cycle Amulet the person can achieve the desired results on the completion of any kind of work. 
            </h4>
            <h4 style={h4style}><b > 5.  Bhibaad Chakra (Enmity Cycle </b>   <br/>
                By wearing this amulet, person can exterminate enemies and rivalry
                </h4>

                <h4 style={h4style}><b > 6.  Iikshya Siddhi Chakra (Wish granting Cycle)  </b>   <br/>
                    By the application of this precious amulet, one can reach to  their goals and aims without any obstacles. 
                    </h4>
                    <h4 style={h4style}><b > 7.  Byaapaar Chakra (Business Cycle </b>   <br/>
                        It is very beneficial if the person wear this amulet before starting business or while facing hindrance and loss during partnership. This amulet magnetizes the profits and gain in business. 
                        </h4>
                        <h4 style={h4style}><b > 8. Paraakram Chakra (Defend Cycle)  </b>   <br/>
                            Paraakram Chakra (Defend Cycle) : In order to calm down the arguments and fight,one must use this amulet. It helps to quiet the controversy and maintain peace and amiability
                            </h4>
                            <h4 style={h4style}><b > 9.  DhanBriddhi Chakra (Wealth increment Cycle)  </b>   <br/>
                                This amulet is very applicable for those who are in financially unstable situation and wants to gain wealth. 
                                </h4>
                                <h4 style={h4style}><b > 10.  Priya Chakra (Relation Cycle)  </b>   <br/>
                                    If the person wishes for goodwill of relatives and  to maintain amicable relation with everyone, then they should wear Priya Chakra amulets. 
                                    </h4>
                                    <h4 style={h4style}><b > 11.   Vojan peya bridhi chakra (Food and Water cycle)  </b>   <br/>
                                        Food and Water cycle : In case of scarcity of food and water in house, community or nation,  they should follow this cycle. 
                                        </h4>
                                        <h4 style={h4style}><b > 12.  Santaan Labh Chakra </b>   <br/>
                                            If someone wants to give birth to son in their family, then they should follow sonbirth cycle. 
                                            </h4>
                                            <h4 style={h4style}><b > 13.   Anti-Leprosy Cycle: </b>   <br/>
                                               
 Person suffering from leprosy must wear this amulet to for gradual recovery to complete healing. Normal people can also wear it to protect oneself from leprosy. 

                                                </h4>
                                                <h4 style={h4style}><b > 14. Naga-Protection Cycle  </b>   <br/>
                                                    By wearing this amulet, person can exterminate enemies and rivalry
                                                    </h4>
                                                    <h4 style={h4style}><b > 15.  God of Earth Protection Cycle </b>   <br/>
                                                        God of Earth Protection Cycle: This cycle helps to prevent us from all kind of curse of earth. 
                                                        </h4>
                                                        <h4 style={h4style}><b > 16.  Lineage god cycle </b>   <br/>
                                                            Lineage god cycle: If there is difficulty in doing regular puja of home along with mental disturbance in family member, one should follow this cycle to avoid such adversity. 
                                                            </h4>
                                                            <h4 style={h4style}><b > 17.  Family Conflict Cycle  </b>   <br/>
                                                                Family Conflict Cycle:  This amulet is to clear away fights between family members or relatives and to maintain love and peace among them. 
                                                                </h4>
                                                                <h4 style={h4style}><b > 18. Weapon Cycle  </b>   <br/>
                                                                    Weapon Cycle: People should follow weapon cycle in order to survive from enemy weaponry charges or to get themselves free from getting harmed through weapons in any case or accidents. 
                                                                    </h4>
                                                                    <h4 style={h4style}><b > 19.  Defect remedy cycle </b>   <br/>
                                                                        Defect remedy cycle: If there is any kind frailty on our luck or persistency of disturbance in our work, then we should follow defect remedy cycle to control and prevent them. 
                                                                        </h4>
                                                                        <h4 style={h4style}><b > 20. ShatruRaksha Cycle </b>   <br/>
                                                                            One should wear this amulet to secure oneself from robbery, theft,  and any sort of possible threat from foes. 
                                                                            </h4>
                                                                            <h4 style={h4style}><b > 21.   Graha-Nakshatra Cycle  </b>   <br/>
                                                                                Graha-Nakshatra Cycle (astrological cycle) : In case of losing interest on study, loss in business,  chaos in mental peace then by wearing this amulet, one can get rid of these disturbances. 
 

                                                                                </h4>
                                                                                <h4 style={h4style}><b > 22.   All Disease Protection cycle   </b>   <br/>
                                                                                    This cycle protects people from having diseases or to fight against the diseases. 

                                                                                    </h4>
                                                                                    <h4 style={h4style}><b > 23.   DushtaSapanaRaksha Cycle   </b>   <br/>
                                                                                        If the person is troubled with having regular bad nightmare like dead person and other disturbing dreams, one should wear this amulet. 
                                                                                        </h4>
                                

        

</div>

    </div>
  )
}
