import React from "react";
import { Link } from "react-router-dom";
import "./style.css";

export default function Footer() {
  return (
    <>
      <section className="footer_img">
        <img src="img/footer.png" alt="" />
      </section>
      <footer>
        <section className="footer wrapper">
          <div className="contact_us">
            <h1>Contact Us</h1>
            <p>
              Mobile,Viber,Whatsapp: +977-9849118562 <br />
              E-mail: info@rdcck.org , rigzindcck@gmail.com<br />
              Website: www.rdcck.org
            </p>
          </div>
          <div className="location">
            <h1>Location</h1>
            <p>
              Rigzin Dechhen Chhoeling Chuglag Khang (RDCCK),
              <br />
              GumbaSauraha Danda, Barasthan, Lauke, Madanpur,
              <br />
              Belkot Municipality-13, Nuwakot, Nepal
            </p>
          </div>
          <div className="location">
            <h1>Date of Establishment</h1>
            <p>15th January, 2015 (Magh 01,2071 B.S.)</p>
            <p>Join Us</p>
            <div className="join_us">
              <a href="https://www.facebook.com/televisionsdharma">
                <i className="fa-brands fa-facebook"></i>
              </a>
              <a href="https://www.youtube.com/channel/UCytux5K4T1wDwxe6tLFnyBw">
                <i className="fa-brands fa-youtube"></i>
              </a>
              <a href="https://twitter.com/dharmatelevision">
                <i className="fa-brands fa-twitter"></i>
              </a>
              <a href="">
                <i className="fa-brands fa-instagram"></i>
              </a>
              <a href="">
                <i className="fa-brands fa-viber"></i>
              </a>
            </div>
          </div>
        </section>
      </footer>
      <section className="copyright">
        Copyright © 2022 Rigzin Dechhen Chhoeling Chuglag Khang All rights
        reserved.
      </section>
      <section className="buttom_navigation_bar">
        <div className="menu">
          <Link to="/">
            <i className="fa-solid fa-house"></i>
            <p>Home</p>
          </Link>
        </div>
        <div className="menu">
          <Link to="aboutus">
            <i className="fa-solid fa-address-card"></i>
            <p>About Us</p>
          </Link>
        </div>
        <div className="menu">
          <Link to="/projects">
            <i className="fa-solid fa-diagram-project"></i>
            <p>Project</p>
          </Link>
        </div>
        <div className="menu">
          <Link to="/services">
            <i className="fa-solid fa-sitemap"></i>
            <p>Services</p>
          </Link>
        </div>
      </section>
    </>
  );
}
