import React, { useState } from "react";
import axios from "axios";
import { Link } from 'react-router-dom'
import "./style.css";
import "./login.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Signup() {
    //  const [firstName,setFirstName]=useState('')
    //    const [lastName,setLastName]=useState('')
    //    const [error,setError]=useState(false)
    //    const handleSubmit=(e)=>{
    //      e.preventDefault();
    //      if(firstName.length==0||lastName.length==0){
    //          setError(true)
    //      }
    //      if(firstName&&lastName)
    //      {
    //      console.log("First Name: ",firstName,"\nLast Name: ",lastName)
    //      }
    //  }
  const url = "http://192.168.1.67:8083/api/auth/signup";
  const [data, setData] = useState({
    name: "",
    username: "",
    address: "",
    email: "",
    password: ""
  });

  function submit(e) {
    const params = {
      name: data.name,
      username: data.username,
      address: data.address,
      email: data.email,
      password: data.password
    };
    console.log(params)
    e.preventDefault();
    axios.post(url, params).then((response) => {
      const data = response.data;
      console.log(response);
      if (data.success) {
        toast.success(data.message);
      } else {
        toast.error(data.message);
      }
    });
    
  }
       function handle(e){
           const newData = {...data}
           newData[e.target.id] = e.target.value
           setData(newData)}
    //         e.preventDefault();
    //       if(firstName.length==0||lastName.length==0){
    //           setError(true)
    //       }
    //       if(firstName&&lastName)
    //       {
    //       console.log("First Name: ",firstName,"\nLast Name: ",lastName)
    //       }
    //    }

  return (
    <>
      <p className="display"></p>
      <form onSubmit={(e) => submit(e)}>
        <section className="fullbox">
          <div className="login_box">
            <div className="logo_img_2">
              <a href="index.html">
                {" "}
                <img src="img/logo.png" alt="" />
              </a>
              <h2>RDCCK</h2>
            </div>
            <div className="inputs">
              <p>Name :</p>
              <input
                type="text"
                placeholder="Enter your name :"
                value={data.name}
                onChange={(e) => handle(e)}
                id="name"
              />
              {/* {error&&firstName.length<=0?
  <label> Name can't be Empty</label>:""} */}
            </div>
            <div className="inputs">
              <p>Username :</p>
              <input
                type="text"
                placeholder="Enter your username :"
                value={data.username}
                onChange={(e) => handle(e)}
                id="username"
              />
              {/* {error&&firstName.length<=0?
  <label> Name can't be Empty</label>:""} */}
            </div>
            <div className="inputs">
              <p>Address :</p>
              <input
                type="text"
                placeholder="Enter your address :"
                value={data.address}
                onChange={(e) => handle(e)}
                id="address"
              />
              {/* {error&&lastName.length<=0?
  <label>Last Name can't be Empty</label>:""} */}
              {" "}
            </div>
            {" "}
            <div className="inputs">
               <p>Email :</p>
              {" "}
              <input
                type="text"
                placeholder="Enter your email :"
                value={data.email}
                onChange={(e) => handle(e)}
                id="email"
              />
              {" "}
            </div>
            {" "}
            <div className="inputs">
               <p>Password :</p>
              {" "}
              <input
                type="password"
                placeholder="Enter your password :"
                value={data.password}
                onChange={(e) => handle(e)}
                id="password"
              />
              {" "}
            </div>
            {" "}
            <button className="signup login_button" type="submit">
               <p>Sign up</p>
              {" "}
            </button>
             <ToastContainer />
            {" "}
            <div className="sponsor">
            <Link to="/supprtus">
                 <button  type="submit">
               <p>Sign up as Sponsor</p>
             </button>
         </Link>
         </div>
            {" "}
          </div>
          {" "}
        </section>
        {" "}
      </form>
      {" "}
    </>
  );
}
export default Signup;
//   import React, { useState } from "react";
//   import "./Form.css";
//   function Form(){
//       const [firstName,setFirstName]=useState('')
//       const [lastName,setLastName]=useState('')
//       const [error,setError]=useState(false)

//       const handleSubmit=(e)=>{
//           e.preventDefault();
//           if(firstName.length==0||lastName.length==0){
//               setError(true)
//           }
//           if(firstName&&lastName)
//           {
//           console.log("First Name: ",firstName,"\nLast Name: ",lastName)
//           }
//       }
//       return(
//           <>
//              <form onSubmit={handleSubmit}>
//                  <div>
//                      <input placeholder="First Name" onChange={e=>setFirstName(e.target.value)} />
//                  </div>
//                  {error&&firstName.length<=0?
//                  <label>First Name can't be Empty</label>:""}
//                  <div>
//                      <input placeholder="Last Name" onChange={e=>setLastName(e.target.value)} />
//                  </div>
//                  {error&&lastName.length<=0?
//                  <label>Last Name can't be Empty</label>:""}
//                  <div>
//                      <button>
//                          Submit
//                      </button>
//                  </div>
//              </form>
//           </>
//       );
//   }
//   export default Form;
//   import React, { useState } from "react";
//   import "./Form.css";
//   function PostForm(){
//       const [firstName,setFirstName]=useState('')
//       const [lastName,setLastName]=useState('')
//       const [error,setError]=useState(false)

//       const handleSubmit=(e)=>{
//           e.preventDefault();
//           if(firstName.length==0||lastName.length==0){
//               setError(true)
//           }
//           if(firstName&&lastName)
//           {
//           console.log("First Name: ",firstName,"\nLast Name: ",lastName)
//           }
//       }
//       return(
//           <>
//              <form onSubmit={handleSubmit}>
//                  <div>
//                      <input placeholder="First Name" onChange={e=>setFirstName(e.target.value)} />
//                  </div>
//                  {error&&firstName.length<=0?
//                  <label>First Name can't be Empty</label>:""}
//                  <div>
//                      <input placeholder="Last Name" onChange={e=>setLastName(e.target.value)} />
//                  </div>
//                  {error&&lastName.length<=0?
//                  <label>Last Name can't be Empty</label>:""}
//                  <div>
//                      <button>
//                          Submit
//                      </button>
//                  </div>
//              </form>
//           </>
//       );
//   }
//   export default PostForm;
