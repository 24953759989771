import React from 'react'
import "./style.css"

export default function Projects() {
    const style = {
        textAlign : "center",
        margin : "10px auto"
    }
    const nustyle = {
      textAlign : "center",
      margin : "40px auto"
  }
    
    const firststyle = {
        textAlign : "center",
        margin : "25px auto"
    }
    const secondstyle = {
        textAlign : "center",
        margin : "50px auto"
    }
  return (
    <>
     <section className="cover_page">
      <h1 style={firststyle}><b>Masterplan Projects of RDCCK, RDSCL, TBMC & Nunnry  </b> </h1>
      <img src="img/Cover Page (4).jpg" alt=""  />
    </section>
    <div className="wrapper temporary_img ">
              
<img src="nunery monastic college/7.jpg" alt="" />
<img src="nunery monastic college/8.jpg" alt="" />
<img src="nunery monastic college/9.jpg" alt="" />
<img src="nunery monastic college/10.jpg" alt="" />
<img src="nunery monastic college/11.jpg" alt="" />
<img src="nunery monastic college/12.jpg" alt="" />

          </div>  
    <h1 style={firststyle}>
      Projects
    </h1>
    <h2 style={firststyle}>
      Stage of a monastic school construction
    </h2>
    <section className="full_monastic wrapper">
      <div className="full_monastic_img_desc">
        <h4>
          Building of monastic school was started to construct from 2016 and now
          has been stopped from end of 2017 since the lack of financial
          managment. Land of monastic school is about 105 ropani and one
          monastic building was being constructed with the income of dharma
          activities like taking pilgrimage tour to India and Nepal but there
          were no progress in the construction in 2018 since our monastic school
          could not organize any pilgrimage tour program for the last one and
          half year.
        </h4>
      </div>
      <div className="full_monastic_img">
        <img src="img/project_1.jpg" alt="" />
      </div>
    </section>
    <section className="full_monastic wrapper">
      <div className="full_monastic_img">
        <img src="img/Screenshot 2022-10-02 102615.png" alt="" />
      </div>
      <div className="full_monastic_img_desc">
        <h4>
          Till now we are using these small temporary halls for residence,
          Assembly hall and class rooms. <br />
          There are two hall 20x40 fits in sized. <br />
          This is monastry and class room.
        </h4>
      </div>
    </section>
    <section className="obstacles wrapper">
        <h1>Obstacles arise in between us</h1>
        <h4>
            Major obstacle to build up the monastic school is financial problems
            since school is not able to collect donation from donors and not being
            able to organize pilgrimage tour as before. <br/>
            Our school is being established and running according to plan and
            vision of Venerable Khen Rinpoche Sonam Gyurme Tamang.
            Ven. Khenpo Sonam Gyurme Tamang is the senior Khenpo in our
            Tamang community and very active and popular in entire Nepal.
            Ven Khenpo wants to preserve and spread Buddha dharma all over
            Nepal and world with traditional Buddhism education and practical
            concepts by establishing monastic school, Sedra and meditation center. <br/>
            Especially Ven. Khenpo is one of the senior and active lecturer, and he
            is being well known in the field of academic and Buddhist media sectors
            across Nepal.
            He is giving lecture in two colleges affiliated to Lumbini University of
            Govt. of Nepal and involved in televisions.
            Ven. Khenpo has started online TV by his own efforts from 2009 and
            now being little more effective and serving society by broadcasting live
            religious program of renowned masters.

        </h4>
      </section>
    <section className="our_products">
        <h1>Pictures of monastic school</h1>
        <div className="wrapper products_img_details">
          <div className="product"><img src="photos/14.jpg" alt="" /></div>
          <div className="product"><img src="photos/17.jpg" alt="" /></div>
          <div className="product"><img src="photos/7.jpg" alt="" /></div>
          <div className="product"><img src="photos/9.jpg" alt="" /></div>
          <div className="product"><img src="photos/10.jpg" alt="" /></div>
          <div className="product"><img src="photos/11.jpg" alt="" /></div>
          <div className="product"><img src="photos/12.jpg" alt="" /></div>
          <div className="product"><img src="photos/1.jpg" alt="" /></div>
          <div className="product"><img src="photos/13.jpg" alt="" /></div>
          <div className="product"><img src="photos/16.jpg" alt="" /></div>
          <div className="product"><img src="photos/18.jpg" alt="" /></div>
          <div className="product"><img src="photos/3.jpg" alt="" /></div>
          <div className="product"><img src="photos/22.jpg" alt="" /></div>
          <div className="product"><img src="photos/14.jpg" alt="" /></div>
          <div className="product"><img src="photos/20.jpg" alt="" /></div>
          <div className="product"><img src="photos/15.jpg" alt="" /></div>

        </div>
      </section>
      <h1 style={secondstyle}>RDSCL Temporary monastic college <br/> construction at Jorpati,
        Kathmandu,Nepal</h1>
      <section className="full_monastic wrapper">
        <div className="full_monastic_img_desc">
          <h4>
            Recently around 30 monks and teaching staffs live here at shelter.
            We have only one temporary shelter where we organize puja, meetings,
            gatherings, teaching classes and even we use it aews bed room.
          </h4>
        </div>
        <div className="full_monastic_img">
          <img src="img/group.png" alt="" />
          
        </div>
      </section>
      <section className="full_monastic wrapper">
        <div className="full_monastic_img">
          <img src="temporary monastic college/IMG_20200928_152136.jpg" alt="" />
        </div>
        <div className="full_monastic_img_desc">
          <h4>
            Monastic college needs 3 class rooms, 1 digital library, 3 dormitory with the
  capacity of 150 students, 1 office room, 1 kitchen, 1 large pray hall for meditation
  practice, group teachings and group meetings.
          </h4>
        </div>
      </section>
      <section className="wrapper temporary">
        <h3 style={firststyle}>Monks and Teaching Staffs </h3>
        <div className="temporary_img">
            <img src="img/okey (1).png" alt="" />
            <img src="img/okey (2).png" alt="" />
            <img src="img/okey (3).png" alt="" />
            <img src="img/okey (4).png" alt="" />
        </div>    
    </section>
      <section className="wrapper temporary">
        <h3 style={firststyle}>Project update</h3>
          <div className="temporary_img">
              <img src="temporary monastic college/IMG_20200928_152120.jpg" alt="" />
              <img src="temporary monastic college/IMG_20200928_152136.jpg" alt="" />
              <img src="temporary monastic college/IMG_20200928_152149.jpg" alt="" />
              <img src="temporary monastic college/IMG_20200928_152715.jpg" alt="" />
              <img src="temporary monastic college/IMG_20210619_164551.jpg" alt="" />
              <img src="temporary monastic college/IMG_20200928_160920.jpg" alt="" />
              <img src="temporary monastic college/IMG_20210615_122151.jpg" alt="" />
              <img src="temporary monastic college/IMG_20210615_122701.jpg" alt="" />
              <img src="temporary monastic college/IMG_20210618_085706.jpg" alt="" />
              <img src="temporary monastic college/IMG_20200928_152715.jpg" alt="" />
          </div>    
      </section>
      <h1 style={secondstyle}>TBMC Retreat Centre at Kakani, <br/> Nuwakot,Nepal</h1>
      <section className="full_monastic wrapper">
        <div className="full_monastic_img_desc">
          <h4>
              We have specific one year retreat program for RDCCK students .
              Many dharma masters and practitiners have requested to organize retreat
              programs in Nepali languages. In Nepal there is no retreat centres where Nepali
              masters can receive entire retreat cources in Nepali so demand and requests are
              very high but due to lack of financial supporters we were unable to build this
              centre from 2016. In present, there is small retreat house which is uncompleted
              till now from 2019.
          </h4>
        </div>
        <div className="full_monastic_img">
          
          <img src="img/one (7).jpeg " alt="" />
        </div>
      </section>
      <section className="full_monastic wrapper">
        <div className="full_monastic_img">
          <img src="img/trust.jpg" alt=""  />
        </div>
        <div className="full_monastic_img_desc">
          <h4>
              Now, In first stage we are planing to build retreat centre with 24 rooms.
              Practitioners from any counties can participate here for retreat programs in
              future. We request generious donors to support retreat centre.
          </h4>
        </div>
      </section>
      <section className="wrapper temporary">
        <h1 style={firststyle}>Project update</h1>
          <div className="temporary_img">
              
              <img src="selectied for meditation center/1.jpeg" alt="" />
              <img src="selectied for meditation center/9.jpeg" alt="" />
              <img src="selectied for meditation center/Aryawalokeshor.jpeg" alt="" />
              <img src="selectied for meditation center/back.jpeg" alt="" />
              <img src="selectied for meditation center/meditation place.jpeg" alt="" />
              <img src="selectied for meditation center/1 .jpg" alt="" />
          </div>    
      </section>
    <h1 style={firststyle}>Medatation Center Project</h1>
      <div className="main_img wrapper">
        
        <img src="img/1 MEDITATION CENTRE PROJECT.jpeg" alt="" />
        <h3 style={style}>Medatation Center Project</h3>
        <div className=" products_img_details">
          <div className="product"><img src="meditation center/p.jpg" alt="" /></div>
          <div className="product"><img src="meditation center/selected hill .jpg"  alt="" /></div>
          <div className="product"><img src="meditation center/n.jpg"  alt="" /></div>
          <div className="product"><img src="meditation center/Park and lokeshor.jpeg"  alt="" /></div>
          <div className="product"><img src="meditation center/m.jpg"  alt="" /></div>
          <div className="product"><img src="img/trust.jpg" alt="" /></div>
          <div className="product"><img src="img/one (7).jpeg" alt="" /></div>
          <div className="product"><img src="meditation center/Chandra kar gumba.jpeg"  alt="" /></div>
        </div>
      </div>
      <div className="wrapper">
      <h1 style={nustyle}>Nunnery Monastic College</h1>
      <h4> Venerable Khen Rinpoche has a specific education
project to support female by leading them to higher
Buddhist education and practices under one roof.Generally, in Asia female are dominated by male in the
society, many female without any reason they are
limited at house and they have no right to make their
choice for personal life.In the past few years many young and middle aged
female has made special request to support them with
the guidance of Buddhism and Buddhist studies and
practices thus Khen Rinpochela has a specific education
project of nunnery college where female can study upto
MA in Buddhism and practices for free of cost.</h4>
<div className='nunery_monastic'>
<img src="nunery monastic college/6.jpg" alt=""  />
</div>
          
<div className="temporary_img ">
              
<img src="nunery monastic college/Elevation of Assembly Ground.jpeg" alt="" />
<img src="nunery monastic college/Flag with Front view.jpeg" alt="" />
<img src="nunery monastic college/Front Elevation .jpeg" alt="" />
<img src="nunery monastic college/nune to Change.jpeg" alt="" />
<img src="nunery monastic college/west  Elevation .jpeg" alt="" />
<img src="nunery monastic college/Prospective Elevation of Gumba.jpeg" alt="" />
<img src="nunery monastic college/5.jpg" alt="" />
<img src="nunery monastic college/Elevation of Assembly Ground.jpeg" alt="" />

          </div>  
        {/* <div className=" products_img_details">
          <div className="product"><img src="nunery monastic college/Elevation of Assembly Ground.jpeg" alt="" /></div>
          <div className="product"><img src="nunery monastic college/Flag with Front view.jpeg"  alt="" /></div>
          <div className="product"><img src="nunery monastic college/Front Elevation .jpeg" /></div>
          <div className="product"><img src="nunery monastic college/Gallery.jpeg"  alt="" /></div>
          <div className="product"><img src="nunery monastic college/Gallery .jpeg"  alt="" /></div>
          <div className="product"><img src="nunery monastic college/nune to Change.jpeg" alt="" /></div>
          <div className="product"><img src="nunery monastic college/west  Elevation .jpeg" alt="" /></div>
          <div className="product"><img src="nunery monastic college/Prospective Elevation of Gumba.jpeg"  alt="" /></div>
        </div> */}
      </div>
      <div className="wrapper">
      <h1 style={nustyle}> Monastic Guest House</h1>
      <div className='nunery_monastic'>
      <img src="nunery monastic college/3.jpg" alt="" />
</div>
<div className="temporary_img ">
              
<img src="nunery monastic college/1.jpg" alt="" />
<img src="nunery monastic college/2.jpg" alt="" />

          </div>  
        {/* <div className=" products_img_details">
          <div className="product"><img src="nunery monastic college/Elevation of Assembly Ground.jpeg" alt="" /></div>
          <div className="product"><img src="nunery monastic college/Flag with Front view.jpeg"  alt="" /></div>
          <div className="product"><img src="nunery monastic college/Front Elevation .jpeg" /></div>
          <div className="product"><img src="nunery monastic college/Gallery.jpeg"  alt="" /></div>
          <div className="product"><img src="nunery monastic college/Gallery .jpeg"  alt="" /></div>
          <div className="product"><img src="nunery monastic college/nune to Change.jpeg" alt="" /></div>
          <div className="product"><img src="nunery monastic college/west  Elevation .jpeg" alt="" /></div>
          <div className="product"><img src="nunery monastic college/Prospective Elevation of Gumba.jpeg"  alt="" /></div>
        </div> */}
      </div>
    </>
  )
}
